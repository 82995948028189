import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicProgressCircle = dynamic(
	async () =>
		await import('@swc-react/progress-circle').then(
			({ ProgressCircle }) => ProgressCircle
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const ProgressCircle: typeof DynamicProgressCircle = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/progress-circle/sp-progress-circle.js')
	}, [])

	return <DynamicProgressCircle {...props} />
}