import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicPicker = dynamic(
  async () => await import('@swc-react/picker').then(({ Picker }) => Picker),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Picker: typeof DynamicPicker = props => {
  useEffect(() => {
    import('@spectrum-web-components/picker/sp-picker.js')
  }, [])

  return <DynamicPicker {...props} />
}
