import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicProgressBar = dynamic(
  async () =>
    await import('@swc-react/progress-bar').then(
      ({ ProgressBar }) => ProgressBar
    ),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const ProgressBar: typeof DynamicProgressBar = props => {
  useEffect(() => {
    import('@spectrum-web-components/progress-bar/sp-progress-bar.js')
  }, [])

  return <DynamicProgressBar {...props} />
}
