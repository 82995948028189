import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { sync } from '@store/slices/syncSlice'
import { bindActionCreators } from '@reduxjs/toolkit'

export const useSync = () => {
  const state = useSelector((state: RootState) => ({
    ...state.sync,
    filteredRemoteUsers: state.sync.remoteUsers.filter(
      ({ user }) => user.uuid !== state.auth.localUser?.uuid
    )
  }))
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(sync.actions, dispatch)

  return { ...state, ...boundedActions }
}

export type SyncStateProperties = ReturnType<typeof useSync>
