import { useAuth } from '@hooks/useAuth'
import { useCreateProjectWithDocumentMutation } from '@store/graphql/__generated__/schema'
import { useRef } from 'react'
import { Button } from 'ui'

export function UploadProjectButton() {
  const { localUser } = useAuth()
  const hiddenFileInputRef = useRef<HTMLInputElement>(null)

  const [createProjectWithDocumentMutation] =
    useCreateProjectWithDocumentMutation()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return

    const fileUploaded = event.target.files[0]
    fileUploaded.text().then(text => {
      return createProjectWithDocumentMutation({
        variables: {
          name: `Project`,
          document: text,
          tags: ['lifestyle', 'tech']
        }
      })
    })
  }
  return (
    <div>
      <Button
        variant="secondary"
        disabled={!localUser}
        onClick={() => hiddenFileInputRef.current?.click()}>
        Upload design
      </Button>
      <input
        type="file"
        ref={hiddenFileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  )
}
