import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicDialogWrapper = dynamic(
	async () =>
		await import('@swc-react/dialog').then(
			({ DialogWrapper }) => DialogWrapper
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const DialogWrapper: typeof DynamicDialogWrapper = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/dialog/sp-dialog-wrapper.js')
	}, [])

	return <DynamicDialogWrapper {...props} />
}
