import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicDialogBase = dynamic(
	async () =>
		await import('@swc-react/dialog').then(({ DialogBase }) => DialogBase),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const DialogBase: typeof DynamicDialogBase = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/dialog/sp-dialog-base.js')
	}, [])

	return <DynamicDialogBase {...props} />
}
