import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicPopover = dynamic(
	async () => await import('@swc-react/popover').then(({ Popover }) => Popover),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Popover: typeof DynamicPopover = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/popover/sp-popover.js')
	}, [])

	return <DynamicPopover {...props} />
}
