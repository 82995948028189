import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import { userLoaded } from '@store/slices/authSlice'
import {
  loadProject,
  projectLoaded,
  resetState
} from '@store/slices/projectSlice'
import { PayloadType, RootState } from '@store/store'
import { PayloadAction } from '@reduxjs/toolkit'
import { setEngineState } from '@store/slices/sceneSlice'

function* handleLoadProject({
  payload: { requireAuth }
}: PayloadAction<PayloadType<typeof loadProject>>) {
  if (requireAuth) {
    yield call(ensureUserLoaded)
  }

  yield put(projectLoaded())
}

function* ensureUserLoaded() {
  // Sometimes, the user loads before loadProject() gets called
  const { localUser } = yield select((state: RootState) => state.auth)
  if (!localUser) yield take(userLoaded.type)
}

function* handleSetEngineState({
  payload
}: PayloadAction<PayloadType<typeof setEngineState>>) {
  if (payload !== 'UNMOUNTED') return

  yield put(resetState())
}

export default function* projectSaga() {
  yield takeEvery(loadProject.type, handleLoadProject)
  yield takeEvery(setEngineState.type, handleSetEngineState)
}
