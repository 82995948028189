import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '@store/slices/authSlice'
import { bindActionCreators } from '@reduxjs/toolkit'

export const useAuth = () => {
  const state = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(auth.actions, dispatch)

  return { ...state, ...boundedActions }
}

export type SyncStateProperties = ReturnType<typeof useAuth>
