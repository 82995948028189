import AdobeIMS from '@services/auth/IMS'
import { StatusCodes } from 'http-status-codes'
import {
  apply,
  fork,
  select,
  take,
  call,
  put,
  delay,
  actionChannel,
  all,
  ActionPattern,
  takeEvery,
  spawn,
  TakeEffect
} from 'redux-saga/effects'
import { ConnectionState, Socket as PhoenixSocket, Channel } from 'phoenix'
import { RootState } from '@store/store'
import Context from '@store/middleware/context'
import { PayloadAction, createAction } from '@reduxjs/toolkit'
import { setShowRefreshModal } from '@store/slices/projectSlice'
import * as Sentry from '@sentry/nextjs'
const AbsintheSocket = require('@absinthe/socket')
const { createAbsintheSocketLink } = require('@absinthe/socket-apollo-link')
import client, { createHttpSplitLink } from '@store/graphql/client'
import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { EventChannel, Task, eventChannel } from 'redux-saga'
import { userLoaded } from '@store/slices/authSlice'
import { EngineState } from '@store/slices/sceneSlice'

export type SocketName = 'sync' | 'document' | 'chime'

export const createSocket = createAction<{
  socketName: SocketName
  projectUuid: string
}>('createSocket')
export const socketCreated = createAction<SocketName>('socketCreated')
export const destroySocket = createAction<SocketName>('destroySocket')
export const createAbsintheSocket = createAction<void>('createAbsintheSocket')
const absintheSocketActionChannelReady = createAction<void>('socketSagaReady')
export const socketDestroyed = createAction<SocketName>('socketDestroyed')
export const cancelWatchSocket = createAction<SocketName>('cancelWatchSocket')

const SOCKET_CONNECTION_MONITORING_INTERVAL = 2000

async function getSignedUrl() {
  const accessToken = await AdobeIMS.getAccessTokenAsync()

  try {
    if (!AdobeIMS.isAccessTokenValid()) {
      throw new Error('Access token is not valid despite just refreshing it.')
    }

    const res: Response = await fetch(
      process.env.NEXT_PUBLIC_SERVICE_CORE_SIGNED_URL_ENDPOINT!,
      {
        headers: {
          Authorization: accessToken!.token,
          'X-Gw-Cookie-Path': 'service/core/socket/websocket'
        }
      }
    )

    const { message, status } = await res.json()

    if (status === StatusCodes.CREATED) {
      return message
    }
  } catch (err) {
    Sentry.captureException(err)
    // rethrow so that createSocket can catch and handle
    throw err
  }
}

function* createRawPhoenixSocket(): Generator<any, PhoenixSocket | null, any> {
  let signedUrl: string = ''
  let gwSig: string = ''

  if (process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT !== 'local') {
    signedUrl = yield call(getSignedUrl)
    const queryParams = new URL(signedUrl).searchParams
    gwSig = queryParams.get('gw_sig') as string
  }

  const accessToken = (yield AdobeIMS.getAccessTokenAsync()).token

  const socket = new PhoenixSocket(
    process.env.NEXT_PUBLIC_SERVICE_CORE_SOCKET_ENDPOINT!,
    {
      heartbeatIntervalMs: 2000,
      rejoinAfterMs: () => 604800000 * 52, // one year
      reconnectAfterMs: () => 604800000 * 52, // one year
      params: {
        token: accessToken,
        ...(gwSig && { gw_sig: gwSig })
      }
    }
  )

  socket.connect()
  return socket
}

function* createSocketActionChannel() {
  let createSocketAttempts: number = 0

  const createSocketChannel: ActionPattern = yield actionChannel(
    createSocket.type
  )

  while (true) {
    const action: TakeEffect = yield take(createSocketChannel)

    //@ts-expect-error
    const {
      socketName,
      projectUuid
    }: { socketName: SocketName; projectUuid: string } = action.payload

    try {
      ++createSocketAttempts

      const socket: PhoenixSocket = yield call(createRawPhoenixSocket)
      const channel: Channel = yield socket.channel(
        `channel:${socketName}:${projectUuid}`
      )

      yield channel.join()

      Context[socketName].socket = socket
      Context[socketName].channel = channel

      yield put(socketCreated(socketName))
      createSocketAttempts = 0
    } catch (err) {
      if (createSocketAttempts < 10) {
        yield put(createSocket({ socketName, projectUuid }))
        ++createSocketAttempts
      } else {
        if (socketName === 'document') {
          yield put(setShowRefreshModal(true))
        }
      }
    }
  }
}

function* monitorSocket(socketName: SocketName) {
  // Initialize the timer
  let watchStartTime: number = Date.now()

  while (true) {
    const projectUuid = yield select(
      (state: RootState) => state.project.projectUuid
    )

    const engineState: EngineState = yield select(
      (state: RootState) => state.scene.engineState
    )
    const socket = Context[socketName].socket
    const channel = Context[socketName].channel

    if (
      projectUuid &&
      socket &&
      channel &&
      engineState === 'INITIALIZED_AND_DOCUMENT_LOADED'
    ) {
      const connectionState = socket?.connectionState()
      const channelState = channel?.state

      yield call(
        console.log,
        `${socketName} socket status: socket is ${connectionState} and channel is ${channelState}`
      )

      if (
        (connectionState !== 'connecting' && connectionState !== 'open') ||
        (channelState !== 'joining' && channelState !== 'joined')
      ) {
        yield put(destroySocket(socketName))

        /* If 20 seconds has gone by without the document socket being connected AND the document channel being joined then show the refresh modal to force the user to refresh the page */
        if (Date.now() - watchStartTime >= 20000 && socketName === 'document') {
          yield put(setShowRefreshModal(true))
          return
        }

        yield take(action => {
          return (
            action.type === socketDestroyed.type &&
            action.payload === socketName
          )
        })

        yield put(createSocket({ socketName, projectUuid }))
      }
    }

    // Only reset the timer if the socket is connected and the channel is joined
    if (socket?.connectionState() === 'open' && channel?.state === 'joined') {
      watchStartTime = Date.now()
    }

    yield delay(2000)
  }
}

function* handleDestroySocket({
  payload: socketName
}: PayloadAction<SocketName>) {
  const socket = Context[socketName].socket
  const channel = Context[socketName].channel

  if (channel) {
    yield apply(channel!, channel!.leave, [])
  }

  if (socket) {
    yield apply(socket, socket.disconnect, [])
  }

  yield put(socketDestroyed(socketName))
}

function createAbsintheSocketWatchChannel(): EventChannel<any> {
  return eventChannel((emit: any) => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      const phoenixSocket: PhoenixSocket = Context.AbsintheSocket?.phoenixSocket
      if (phoenixSocket) {
        emit(phoenixSocket.connectionState())
      }
    }, SOCKET_CONNECTION_MONITORING_INTERVAL)
    return () => clearInterval(intervalId)
  })
}

function* watchAbsintheSocketConnection(): Generator<any, void, any> {
  const monitoringChannel = yield call(createAbsintheSocketWatchChannel)
  try {
    while (true) {
      const connectionState: ConnectionState = yield take(monitoringChannel)

      if (connectionState !== 'connecting' && connectionState !== 'open') {
        Context.AbsintheSocket = null
        yield client.resetStore()
        yield put({ type: createAbsintheSocket.type })
        monitoringChannel.close()
      }
    }
  } catch (err) {
    console.error(err)
  }
}

function* createApolloLink(absintheSocket: typeof AbsintheSocket) {
  const httpSplitLink = createHttpSplitLink()

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    createAbsintheSocketLink(absintheSocket),
    httpSplitLink
  )

  yield apply(client, client.setLink, [splitLink])
}

function* createAbsintheSocketActionChannel(): Generator<any, void, any> {
  const requestChannel: ActionPattern = yield actionChannel(
    createAbsintheSocket.type
  )

  yield put(absintheSocketActionChannelReady())

  while (true) {
    yield take(requestChannel)
    const { localUser } = yield select((state: RootState) => state.auth)

    if (
      localUser &&
      !Context.AbsintheSocket &&
      !Context.AbsintheSocket?.phoenixSocket.isConnected()
    ) {
      const absintheSocket = yield call(handleCreateAbsintheSocket)
      Context.AbsintheSocket = absintheSocket
      yield call(createApolloLink, absintheSocket)
      yield fork(watchAbsintheSocketConnection)
    }
  }
}

function* handleCreateAbsintheSocket(): Generator<any, any, any> {
  try {
    const phoenixSocket = yield call(createRawPhoenixSocket)
    yield phoenixSocket.connect()
    return AbsintheSocket.create(phoenixSocket)
  } catch (err) {
    Sentry.captureException({
      message: `Cannot connect to absinthe socket`,
      error: err
    })

    yield call(console.error, JSON.stringify(err))
  }
}

function* watchAbsintheChannelReady() {
  while (true) {
    yield all([
      take(userLoaded.type),
      take(absintheSocketActionChannelReady.type)
    ])
    yield put(createAbsintheSocket())
  }
}

export default function* socketSaga() {
  yield all([
    spawn(createAbsintheSocketActionChannel),
    spawn(watchAbsintheChannelReady),
    takeEvery(destroySocket.type, handleDestroySocket),
    spawn(createSocketActionChannel),
    spawn(monitorSocket, 'document'),
    spawn(monitorSocket, 'sync')
  ])
}
