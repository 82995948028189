import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTooltip = dynamic(
	async () => await import('@swc-react/tooltip').then(({ Tooltip }) => Tooltip),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Tooltip: typeof DynamicTooltip = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/tooltip/sp-tooltip.js')
	}, [])

	return <DynamicTooltip {...props} />
}
