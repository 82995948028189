import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicFieldLabel = dynamic(
	async () =>
		await import('@swc-react/field-label').then(({ FieldLabel }) => FieldLabel),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const FieldLabel: typeof DynamicFieldLabel = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/field-label/sp-field-label.js')
	}, [])

	return <DynamicFieldLabel {...props} />
}
