import { Presence, Channel } from 'phoenix'
import { eventChannel } from 'redux-saga'
import {
  receivedRemoteUserEmojiMessageEvent,
  receivedRemoteUserMouseMoveEvent,
  receivedRemoteUserLocationChangeEvent,
  receivedRemoteUserInputMessageEvent,
  RemoteUserEmojiMessageEvent,
  RemoteUserMouseMoveEvent,
  RemoteUserInputMessageEvent,
  setRemoteUsers,
  receivedRemotePropertyChangeEvent,
  receivedRemoteUserSubmitMessageEvent,
  receivedRemoteSceneChangeEvent,
  RemotePropertyChangeEvent,
  RemoteSceneChangeEvent,
  receivedRemoteUserPlayerChangeEvent,
  PlayerChangeEvent
} from '@store/slices/syncSlice'
import Context from '../context'
import { User } from '@store/graphql/__generated__/schema'

export function createPresenceEventChannel(
  phoenixPresence: Presence,
  phoenixChannel: Channel,
  localUser: User
) {
  return eventChannel(emit => {
    phoenixPresence.onSync(() => {
      const presenceStateList = phoenixPresence.list()
      presenceStateList.forEach((presenceState: any) => {
        if (presenceState.user.uuid !== localUser.uuid) {
          Context.Engine?.setRemoteUserStatus({
            remote_user_uuid: presenceState.user.uuid,
            status: 'online',
            color: presenceState.user.preferences.color
          })
        }
      })

      emit({
        type: setRemoteUsers.type,
        payload: structuredClone(phoenixPresence.list())
      })
    })

    phoenixChannel.on('presence_diff', (diff: any) => {
      Object.keys(diff.joins).forEach((key: string) => {
        const user = diff.joins[key].user
        if (user.uuid !== localUser.uuid) {
          Context.Engine?.setRemoteUserStatus({
            remote_user_uuid: user.uuid,
            status: 'online',
            color: user.preferences.color
          })
        }
      })

      Object.keys(diff.leaves).forEach((key: string) => {
        const user = diff.leaves[key].user
        if (user.uuid !== localUser.uuid) {
          Context.Engine?.setRemoteUserStatus({
            remote_user_uuid: user.uuid,
            status: 'away'
          })
        }
      })
    })

    return () => phoenixChannel.leave()
  })
}

export function createSocketEventChannel(phoenixChannel: Channel) {
  return eventChannel(emit => {
    // TODO: enable this handler for frames
    // phoenixChannel.on('mouse_move', (data: RemoteUserMouseMoveEvent) => {
    //   emit({
    //     type: receivedRemoteUserMouseMoveEvent.type,
    //     payload: data
    //   })
    // })

    phoenixChannel.on('scene_change', (data: RemoteSceneChangeEvent) => {
      emit({
        type: receivedRemoteSceneChangeEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('property_change', (data: RemotePropertyChangeEvent) => {
      emit({
        type: receivedRemotePropertyChangeEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('location_change', (data: RemoteUserMouseMoveEvent) => {
      emit({
        type: receivedRemoteUserLocationChangeEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('input_message', (data: RemoteUserInputMessageEvent) => {
      emit({
        type: receivedRemoteUserInputMessageEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('submit_message', (data: RemoteUserInputMessageEvent) => {
      emit({
        type: receivedRemoteUserSubmitMessageEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('emoji_message', (data: RemoteUserEmojiMessageEvent) => {
      emit({
        type: receivedRemoteUserEmojiMessageEvent.type,
        payload: data
      })
    })

    phoenixChannel.on('player_change', (data: PlayerChangeEvent) => {
      emit({
        type: receivedRemoteUserPlayerChangeEvent.type,
        payload: data
      })
    })

    return () => phoenixChannel.leave()
  })
}
