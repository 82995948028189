import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicSlider = dynamic(
  async () => await import('@swc-react/slider').then(({ Slider }) => Slider),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Slider: typeof DynamicSlider = props => {
  useEffect(() => {
    import('@spectrum-web-components/slider/sp-slider.js')
  }, [])

  return <DynamicSlider {...props} />
}
