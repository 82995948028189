import React, { CSSProperties, FC } from 'react'
import { Avatar as SpectrumAvatar } from 'ui'

export type Size = 'XS' | 'S' | 'M'

export const getSize = (size: Size) => {
  switch (size) {
    case 'XS':
      return 18
    case 'S':
      return 28
    case 'M':
      return 40
    default:
      return 28
  }
}

interface AvatarProps {
  name?: string
  src?: string
  alt: string
  size?: Size
  disabled?: boolean
  withBorder?: boolean
  borderColor?: string
}

const Avatar: FC<AvatarProps> = ({
  name,
  src,
  alt,
  size = 'S',
  disabled,
  withBorder = false,
  borderColor = 'transparent'
}) => {
  const avatarSize = getSize(size)

  const avatarContainerStyle: CSSProperties = {
    width: avatarSize,
    minWidth: avatarSize,
    height: avatarSize,
    minHeight: avatarSize,
    opacity: disabled ? 0.5 : 1,
    borderRadius: '50%',
    backgroundColor: borderColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const avatarStyle: CSSProperties = {
    objectFit: 'cover',
    width: withBorder ? avatarSize - 4 : avatarSize,
    minWidth: withBorder ? avatarSize - 4 : avatarSize,
    height: withBorder ? avatarSize - 4 : avatarSize,
    minHeight: withBorder ? avatarSize - 4 : avatarSize,
    border: withBorder ? '2px solid #fff' : 'undefined'
  }

  return (
    <>
      {withBorder ? (
        <div style={avatarContainerStyle}>
          <SpectrumAvatar src={src} label={alt} style={avatarStyle} />
        </div>
      ) : (
        <SpectrumAvatar src={src} label={alt} size={400} style={avatarStyle} />
      )}
    </>
  )
}

export default Avatar
