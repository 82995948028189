import React from 'react'
import styles from 'styles/components/Footer.module.scss'
import AdobeLogoText from 'public/adobe-logo-text.svg'
import { Legal } from '@components/legal/Legal'

const Footer = () => {
  return (
    <footer className={styles['container']}>
      <AdobeLogoText className={styles['adobe-logo']} />
      <Legal direction="row" />
    </footer>
  )
}

export default Footer
