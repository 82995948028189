import { createSlice } from '@reduxjs/toolkit'

export interface MonitoringState {
  isAdobeAnalyticsInitialized: boolean
}

const initialState: MonitoringState = {
  isAdobeAnalyticsInitialized: false
}

export const monitoring = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setAdobeAnalyticsInitialized: state => {
      state.isAdobeAnalyticsInitialized = true
    }
  }
})

export const { setAdobeAnalyticsInitialized } = monitoring.actions

export default monitoring.reducer
