import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicIcon = dynamic(
	async () => await import('@swc-react/icon').then(({ Icon }) => Icon),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Icon: typeof DynamicIcon = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/icon/sp-icon.js')
	}, [])

	return <DynamicIcon {...props} />
}
