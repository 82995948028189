import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicFieldGroup = dynamic(
	async () =>
		await import('@swc-react/field-group').then(
			({ FieldGroup }) => FieldGroup
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const FieldGroup: typeof DynamicFieldGroup = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/field-group/sp-field-group.js')
	}, [])

	return <DynamicFieldGroup {...props} />
}