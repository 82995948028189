import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SceneState } from './sceneSlice'

export enum ContentClass {
  AUTO = 'auto',
  PHOTO = 'photo',
  ART = 'art',
  VECTOR = 'vector'
}

export enum AspectRatio {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
  WIDESCREEN = 'WIDESCREEN'
}

export enum PresetCategory {
  ALL = 'All',
  POPULAR = 'Popular',
  MOVEMENTS = 'Movements',
  THEMES = 'Themes',
  TECHNIQUES = 'Techniques',
  EFFECTS = 'Effects',
  MATERIALS = 'Materials',
  CONCEPT = 'Concept',
  COLOR_AND_TONE = 'Color_and_tone',
  LIGHTING = 'Lighting',
  CAMERA_ANGLE = 'Camera_angle'
}

export type StyleImageReference = {
  type: 'preset' | 'user-upload' | 'generated-output'
  url: string
}

export type GeneratedImage = {
  url?: string
  selected: boolean
}

export interface FireflyState {
  aspectRatio: AspectRatio
  contentClass: ContentClass
  generatedImages: GeneratedImage[]
  imageGenerationStatus:
    | 'idle'
    | 'generating-similar-images'
    | 'generating-new-images'
  prompt: string
  stylePresets: string[]
  selectedPresetCategory: PresetCategory
  visibleToast: 'none' | 'completed' | 'error' | 'warning'
  styleImageReference: StyleImageReference | null
  previousSceneFramePropertiesOnShow: {
    frameEnabled: SceneState['frameEnabled']
    frameSize: SceneState['frameSize']
    frameType: SceneState['frameType']
  } | null
  imgReference: 'color' | 'depth'
  sceneColorsAdherence: number
  showOnboardingDialog: boolean
  showUploadingImageRightsDialog: boolean
  showToSPopup: boolean
  showEmptySceneDialog: boolean
}

type KeyValue<
  T extends { [key: string]: any },
  U extends keyof T
> = U extends any ? { key: U; value: T[U] } : never

const initialState: FireflyState = {
  aspectRatio: AspectRatio.LANDSCAPE,
  contentClass: ContentClass.AUTO,
  generatedImages: [
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false }
  ],
  imageGenerationStatus: 'idle',
  prompt: '',
  stylePresets: [],
  selectedPresetCategory: PresetCategory.POPULAR,
  visibleToast: 'none',
  styleImageReference: null,
  previousSceneFramePropertiesOnShow: null,
  imgReference: 'depth',
  sceneColorsAdherence: 50,
  showOnboardingDialog: false,
  showUploadingImageRightsDialog: false,
  showToSPopup: false,
  showEmptySceneDialog: false
}

export type PropertyPayload = KeyValue<FireflyState, keyof typeof initialState>

export const firefly = createSlice({
  name: 'firefly',
  initialState,
  reducers: {
    setPropertyState: (
      state,
      { payload }: PayloadAction<Partial<PropertyPayload>>
    ) => {
      state[payload.key] = payload.value
    },
    addStylePreset: (state, { payload }: PayloadAction<string>) => {
      state.stylePresets = [...state.stylePresets, payload]
    },
    removeStylePreset: (state, { payload }: PayloadAction<string>) => {
      state.stylePresets = state.stylePresets.filter(
        preset => preset !== payload
      )
    },
    clearStylePresets: state => {
      state.stylePresets = []
    },
    generateImages: () => {},
    generateSimilarImages: (
      _,
      { payload }: PayloadAction<{ imgUrl: string }>
    ) => {},
    stopImagGeneration: () => {},
    setGeneratedImages: (
      state,
      { payload }: PayloadAction<FireflyState['generatedImages']>
    ) => {
      state.generatedImages = payload
    },
    setImageGenerationStatus: (
      state,
      { payload }: PayloadAction<FireflyState['imageGenerationStatus']>
    ) => {
      state.imageGenerationStatus = payload
    },
    setPrompt: (state, { payload }: PayloadAction<FireflyState['prompt']>) => {
      state.prompt = payload
    },
    selectGeneratedImage: (
      _,
      { payload }: PayloadAction<{ url: string }>
    ) => {},
    setSelectedPresetCategory: (
      state,
      { payload }: PayloadAction<FireflyState['selectedPresetCategory']>
    ) => {
      state.selectedPresetCategory = payload
    },
    resetState: state => {
      state.aspectRatio = AspectRatio.LANDSCAPE
      state.contentClass = ContentClass.PHOTO
      state.generatedImages = [
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false }
      ]
      state.prompt = ''
      state.imageGenerationStatus = 'idle'
      state.stylePresets = []
      state.selectedPresetCategory = PresetCategory.POPULAR
      state.visibleToast = 'none'
      state.styleImageReference = null
      state.previousSceneFramePropertiesOnShow = null
    },
    setVisibleToast: (
      state,
      { payload }: PayloadAction<FireflyState['visibleToast']>
    ) => {
      state.visibleToast = payload
    },
    setShowOnboardingDialog: (
      state,
      { payload }: PayloadAction<FireflyState['showOnboardingDialog']>
    ) => {
      state.showOnboardingDialog = payload
    },
    setShowUploadingImageRightsDialog: (
      state,
      { payload }: PayloadAction<FireflyState['showUploadingImageRightsDialog']>
    ) => {
      state.showUploadingImageRightsDialog = payload
    },
    setShowToSPopup: (
      state,
      { payload }: PayloadAction<FireflyState['showToSPopup']>
    ) => {
      state.showToSPopup = payload
    },
    setShowEmptySceneDialog: (
      state,
      { payload }: PayloadAction<FireflyState['showEmptySceneDialog']>
    ) => {
      state.showEmptySceneDialog = payload
    }
  }
})

export const {
  clearStylePresets,
  generateImages,
  generateSimilarImages,
  stopImagGeneration,
  resetState,
  setGeneratedImages,
  setImageGenerationStatus,
  setPrompt,
  setPropertyState,
  selectGeneratedImage,
  setSelectedPresetCategory,
  setVisibleToast,
  setShowOnboardingDialog,
  setShowUploadingImageRightsDialog,
  setShowToSPopup,
  setShowEmptySceneDialog
} = firefly.actions

export default firefly.reducer
