import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicActionMenu = dynamic(
	async () =>
		await import('@swc-react/action-menu').then(({ ActionMenu }) => ActionMenu),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const ActionMenu: typeof DynamicActionMenu = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/action-menu/sp-action-menu.js')
	}, [])

	return <DynamicActionMenu {...props} />
}
