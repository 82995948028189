export const kModuleVersion = 2

type TGetModuleVersion = () => number

export function checkModuleVersion(module: any): boolean {
  const f: TGetModuleVersion = module['Neo_GetModuleVersion']
  if (!f) {
    console.log('GetModuleVersion not found in wasm')
    return false
  }

  const currentVersion = f()
  if (currentVersion != kModuleVersion) {
    console.log(
      'module  version mismatch. Expected=' +
        kModuleVersion +
        ' current=' +
        currentVersion
    )
    return false
  }

  return true
}
