import React, { FC, useState } from 'react'

import cn from 'classnames'
import styles from '@styles/components/EmojiPicker.module.scss'

interface EmojiReactionProps {
  emoji?: string
}

const EmojiReaction: FC<EmojiReactionProps> = ({ emoji }) => {
  return (
    <div className={cn(styles['emoji-picker__animation'])}>
      <span>{emoji}</span>
    </div>
  )
}

export default EmojiReaction
