import { FC, memo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '@store/store'
import Cursor from '@components/cursor/Cursor'
import { useSceneState } from '@hooks/useScene'

const mapStateToProps = (state: RootState) => ({
  localUser: state.auth.localUser,
  remoteUsers: state.sync.remoteUsers
})

const connector = connect(mapStateToProps)

type Props = ConnectedProps<typeof connector>

const SyncInterface: FC<Props> = ({ remoteUsers, localUser }) => {
  const showAvatars = useSceneState('showAvatars')

  if (!showAvatars) return null
  return (
    <>
      {remoteUsers?.map(({ user }) => (
        <Cursor
          key={user.uuid}
          hidden={user.uuid === localUser?.uuid}
          user={user}
        />
      ))}
    </>
  )
}

export default connector(memo(SyncInterface))
