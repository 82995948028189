import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicActionButton = dynamic(
	async () =>
		await import('@swc-react/action-button').then(
			({ ActionButton }) => ActionButton
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const ActionButton: typeof DynamicActionButton = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/action-button/sp-action-button.js')
	}, [])

	return <DynamicActionButton {...props} />
}
