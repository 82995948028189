import AdobeIMS from '@services/auth/IMS'
import { User } from '@store/graphql/__generated__/schema'

export interface AdobePPSUserProfile {
  id: User['adobeUserId']
  first_name: string
  last_name: string
  nick_name: string
  company: string
  occupation: string
  website: string
  reference_links: Array<{
    title: string
    url: string
  }>
  social_accounts: Array<{
    provider: string
    value: string
  }>
  create_date: string
  update_date: string
  display_name: string
  images: {
    '50': string
    '100': string
    '115': string
    '138': string
    '230': string
    '276': string
  }
  isDefaultImage: boolean
}

const config: Record<string, string> = {
  applicationIdentifier: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
  ppsEndpoint: process.env.NEXT_PUBLIC_ADOBE_PPS_ENDPOINT!
}

const DefaultAvatarURL = `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/fallback_avatar.png`

// Note: For some reason, PPS is not returning name/email/etc.
// So let's just use the API to retrieve avatars and figure out the rest of the profile data later
// @TODO: When we get the profile data from PPS, we can remove user profile data from our DB.

export async function retrieveAdobeUserAvatarByAdobeUserId(
  adobeUserId: User['adobeUserId']
): Promise<string> {
  const accessToken = await AdobeIMS.getAccessTokenAsync()

  try {
    const publicProfile: AdobePPSUserProfile = await fetch(
      `${config.ppsEndpoint}/api/profile/${adobeUserId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken!.token}`,
          'x-api-key': config.applicationIdentifier
        }
      }
    ).then(r => r.json())

    return publicProfile?.images['50'] || DefaultAvatarURL
  } catch (e) {
    console.error(`Error fetching profile for current user\n`, e)
    return DefaultAvatarURL
  }
}
