import React, { FC, useEffect, memo, useRef } from 'react'
import SyncInterface from '@components/sync/SyncInterface'
import UserFollowingIndicator from '@components/sync/UserFollowingIndicator'
import { connect, ConnectedProps } from 'react-redux'
import {
  loadProject,
  setIsFeatured,
  setName,
  setProjectUuid,
  setOwnerUserUuid
} from '@store/slices/projectSlice'
import { setEngineState } from '@store/slices/sceneSlice'
import { useGetProjectByUuidQuery } from '@store/graphql/__generated__/schema'
import styles from '@styles/components/Studio.module.scss'
import Head from 'next/head'
import { AppNameBeta } from '@constants/appNameBeta'
import FrameDimension from '@components/propertiesPanel/FrameDimension'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useProject } from '@hooks/useProject'
import cn from 'classnames'
import FireflyPreview from '@components/fireflyPanel/FireflyPreview'
import { useFirefly } from '@hooks/useFirefly'
import { AspectRatio } from '@store/slices/fireflySlice'
import { useMaxSize } from '@hooks/useMaxSize'

const AspectRatioMap: Record<AspectRatio, number> = {
  [AspectRatio.LANDSCAPE]: 4 / 3,
  [AspectRatio.PORTRAIT]: 3 / 4,
  [AspectRatio.SQUARE]: 1,
  [AspectRatio.WIDESCREEN]: 16 / 9
}

const mapDispatchToProps = {
  loadProject,
  setIsFeatured,
  setName,
  setProjectUuid,
  setOwnerUserUuid,
  setEngineState
}

const connector = connect(undefined, mapDispatchToProps)

type Props = ConnectedProps<typeof connector> & {
  uuid: string
  loadProject: typeof loadProject
  setIsFeatured: typeof setIsFeatured
  setName: typeof setName
  setProjectUuid: typeof setProjectUuid
  setOwnerUserUuid: typeof setOwnerUserUuid
  setEngineState: typeof setEngineState
}

export const CANVAS_CONTAINER_ID = 'studio-canvas-container'

const Studio: FC<Props> = ({
  uuid,
  setIsFeatured,
  setName,
  setProjectUuid,
  setOwnerUserUuid,
  setEngineState,
  loadProject
}) => {
  const { data } = useGetProjectByUuidQuery({ variables: { uuid } })
  const project = data?.projectByUuid
  const flags = useFlags()

  const { showFireflyPopover } = useProject()
  const { aspectRatio } = useFirefly()

  const itemRef = useRef<HTMLDivElement>(null)

  const itemChildSize = useMaxSize(
    showFireflyPopover ? itemRef.current : null,
    AspectRatioMap[aspectRatio]
  )
  const itemChildStyle =
    showFireflyPopover && itemChildSize ? itemChildSize : undefined

  useEffect(() => {
    if (uuid && project) {
      setProjectUuid(uuid)
      setIsFeatured(project.featured!)
      setName(project.name!)
      setOwnerUserUuid(project.ownerUserUuid || null)
      setEngineState('INITIALIZE')
      loadProject({ requireAuth: true })
    }

    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [uuid, project])

  const documentTitle = project?.name
    ? `${project.name} | ${AppNameBeta}`
    : `${AppNameBeta}`

  return (
    <>
      <Head>
        <title>{documentTitle}</title>
      </Head>
      <div
        className={cn(styles['container'], {
          [styles['two-item-container']]: showFireflyPopover
        })}>
        <div
          className={cn({
            [styles['max-size']]: !showFireflyPopover,
            [styles['item']]: showFireflyPopover,
            [styles['justify-content-end']]: showFireflyPopover
          })}
          ref={itemRef}>
          <div
            id={CANVAS_CONTAINER_ID}
            className={cn({
              [styles['max-size']]: !showFireflyPopover,
              [styles['item-content']]: showFireflyPopover,
              [styles['border-radius']]: showFireflyPopover
            })}
            style={itemChildStyle}>
            <UserFollowingIndicator />
            <SyncInterface />
            {flags['base-pf-ui-frame-dimension'] && <FrameDimension />}
          </div>
        </div>

        {showFireflyPopover && (
          <div className={styles['item']}>
            <div
              className={cn(styles['item-content'], styles['border-radius'])}
              style={itemChildStyle}>
              <FireflyPreview />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default connector(
  memo(Studio, (prevProps, nextProps) => prevProps.uuid === nextProps.uuid)
)
