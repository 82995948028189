export enum BasePfUISceneLayersPanelFlag {
  DISABLED = 'disabled',
  ENABLED_SINGLE_PANEL = 'enabled-single-panel',
  ENABLED_DOUBLE_PANEL = 'enabled-double-panel'
}

export enum BaseTfStudioFinePointerCompatibility {
  COMPATIBLE = 'compatible',
  INCOMPATIBLE = 'incompatible',
  NOT_FULLY_SUPPORTED = 'not-fully-supported'
}
