import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicCheckbox = dynamic(
  async () =>
    await import('@swc-react/checkbox').then(({ Checkbox }) => Checkbox),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Checkbox: typeof DynamicCheckbox = props => {
  useEffect(() => {
    import('@spectrum-web-components/checkbox/sp-checkbox.js')
  }, [])

  return <DynamicCheckbox {...props} />
}
