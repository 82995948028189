import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicAvatar = dynamic(
	async () =>
		await import('@swc-react/avatar').then(
			({ Avatar }) => Avatar
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Avatar: typeof DynamicAvatar = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/avatar/sp-avatar.js')
	}, [])

	return <DynamicAvatar {...props} />
}