import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from '@styles/components/Cursor.module.scss'
// @ts-ignore
import Avatar from '@react/react-spectrum/Avatar'
import EmojiPicker from '@components/emojiPicker/EmojiPicker'
import AnimatedTextInput from '@components/animatedTextInput/AnimatedTextInput'
import EmojiReceiver from '@components/emojiPicker/EmojiReceiver'
import { PresenceUserDataFields } from '@store/slices/syncSlice'
import { useSync } from '@hooks/useSync'

interface CursorProps {
  hidden: boolean
  user: PresenceUserDataFields
}

const rgbColor = (r: number, g: number, b: number) => `rgb(${r}, ${g}, ${b})`

const Cursor = ({ user, hidden }: CursorProps) => {
  const { remoteUserTextMessages, remoteUserEmojiMessages } = useSync()
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [mousePosition, setMousePosition] = useState<{ x: Number; y: number }>({
    x: 0,
    y: 0
  })

  const pauseRef = useRef<boolean>()
  const showEmojiRef = useRef<boolean>()

  const handleKeyPress = (e: KeyboardEvent): void => {
    if (e.key === '/') {
      if (showEmojiRef.current === true) {
        setShowInput(true)
        setShowEmojiPicker(false)
        pauseRef.current = false
      } else {
        setShowEmojiPicker(true)
        pauseRef.current = true
      }
    }
    if (e.key === 'Escape' || e.key === 'Enter') {
      setShowEmojiPicker(false)
      setShowInput(false)
      pauseRef.current = false
    }
  }

  const handleMouseMove = (e: MouseEvent) => {
    const x = e.clientX
    const y = e.clientY
    if (!pauseRef.current) setMousePosition({ x, y })
  }

  useEffect(() => {
    showEmojiRef.current = showEmojiPicker
  }, [showEmojiPicker])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    if (hidden) document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const cursor = useRef(null)

  return (
    <>
      <div
        id={`sync-cursor-${user.uuid}`}
        ref={cursor}
        style={{ visibility: hidden ? 'hidden' : 'visible' }}
        className={cn(styles['cursor'])}>
        <div
          style={{
            background:
              user?.preferences.color && rgbColor(...user.preferences.color)
          }}
          className={styles['cursor__container']}>
          <Avatar
            className={cn(styles['cursor__avatar'])}
            style={{ border: 'none' }}
            src={`${user.adobe_user_avatar_url}`}
            alt={'user avatar'}
          />
          <div className={styles['cursor__name']}>
            {user.adobe_user_display_name}
          </div>
          {remoteUserTextMessages[user.uuid] && (
            <div>
              <AnimatedTextInput
                readOnlyValue={remoteUserTextMessages[user.uuid]}
                isFocused
              />
            </div>
          )}
          <EmojiReceiver emoji={remoteUserEmojiMessages[user.uuid]} />
        </div>
      </div>
      {hidden && (
        <div
          style={{
            position: 'absolute',
            top: `${mousePosition.y}px`,
            left: `${mousePosition.x}px`
          }}
          onKeyUp={handleKeyPress}>
          <div className="flex flex-col gap-m">
            {showEmojiPicker && <EmojiPicker />}
            {showInput && <AnimatedTextInput isFocused />}
          </div>
        </div>
      )}
    </>
  )
}

export default Cursor
