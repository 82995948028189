import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
  fromPromise,
  toPromise,
  split
} from '@apollo/client'
import AdobeIMS from '@services/auth/IMS'

let client: ApolloClient<any>

function createApiKeyLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...{
          'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
        }
      }
    }))

    return forward(operation)
  })
}

export function createAuthLink() {
  const authLink = new ApolloLink((operation, forward) => {
    return fromPromise(
      AdobeIMS.getAccessTokenAsync().then(tokenInfo => {
        if (!tokenInfo || !tokenInfo.token) {
          throw new Error('Could not obtain a new access token')
        }

        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            ...{
              authorization: `Bearer ${tokenInfo?.token}`,
              'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
            }
          }
        }))

        return toPromise(forward(operation))
      })
    )
  })

  return authLink
}

export function createProtectedHttpLink(authLink: ApolloLink) {
  const httpLink = from([
    authLink,
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_SERVICE_CORE_GRAPHQL_ENDPOINT
    })
  ])

  return httpLink
}

export function createUnprotectedHttpLink() {
  const apiLink = createApiKeyLink()
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_SERVICE_CORE_PUBLIC_GRAPHQL_ENDPOINT
  })

  return from([apiLink, httpLink])
}

export function createHttpSplitLink() {
  const unprotectedHttpLink: ApolloLink = createUnprotectedHttpLink()
  const authLink: ApolloLink = createAuthLink()
  const protectedHttpLink: ApolloLink = createProtectedHttpLink(authLink)

  return split(
    () => AdobeIMS.isAccessTokenValid(),
    protectedHttpLink,
    unprotectedHttpLink
  )
}

if (global.window) {
  const splitLink = createHttpSplitLink()

  client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  })
} else {
  client = new ApolloClient({
    cache: new InMemoryCache()
  })
}

export default client
