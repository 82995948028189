import { all, put, putResolve, select, takeEvery } from 'redux-saga/effects'
import {
  ArchiveUserLibraryAssetRequest,
  CreateUserLibraryAssetRequest,
  UserLibraryBackground,
  UserLibraryMaterial,
  applyUserLibraryBackground,
  applyUserLibraryMaterialToSelection,
  archiveUserLibraryAsset,
  createUserLibraryAsset,
  fetchAllUserLibraryAssets,
  fetchUserLibraryBackgrounds,
  fetchUserLibraryMaterials,
  setAllUserLibraryAssets,
  setUserLibraryBackgrounds,
  setUserLibraryMaterials
} from '@store/slices/userLibrarySlice'
import ApolloClient from '@store/graphql/client'
import {
  ArchiveUserLibraryAssetDocument,
  Asset,
  CreateUserLibraryAssetDocument,
  GetAllUserLibraryAssetsDocument,
  GetAllUserLibraryAssetsQueryResult,
  GetUserLibraryAssetsByTypeDocument,
  GetUserLibraryAssetsByTypeQueryResult
} from '@store/graphql/__generated__/schema'
import { PayloadAction } from '@reduxjs/toolkit'
import { SceneState, setPropertyState } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { PropertyPayload } from '../slices/sceneSlice'
import Context from './context'

// Deserialize the asset.document JSON string into a Javascript object
function deserializeAssetDocument(asset: Asset | null) {
  return {
    ...asset,
    document: JSON.parse(asset!.document!)
  }
}

// Fetch all Assets belonging to current User
function* handleFetchAllUserLibraryAssets() {
  const res: GetAllUserLibraryAssetsQueryResult = yield ApolloClient.query({
    fetchPolicy: 'network-only',
    query: GetAllUserLibraryAssetsDocument
  })

  const assets = res.data?.userLibraryAssets

  if (assets && assets.length > 0) {
    yield put(setAllUserLibraryAssets(assets.map(deserializeAssetDocument)))
  } else {
    yield put(setAllUserLibraryAssets([]))
  }
}

// Fetch all Assets with type: 'material'  belonging to current User
function* handleFetchUserLibraryMaterials() {
  const res: GetUserLibraryAssetsByTypeQueryResult = yield ApolloClient.query({
    fetchPolicy: 'network-only',
    query: GetUserLibraryAssetsByTypeDocument,
    variables: {
      type: 'material'
    }
  })

  const materials = res.data?.userLibraryAssetsByType

  if (materials && materials.length > 0) {
    yield put(setUserLibraryMaterials(materials.map(deserializeAssetDocument)))
  } else {
    yield put(setUserLibraryMaterials([]))
  }
}

// Fetch all Assets with type: 'background'  belonging to current User
function* handleFetchUserLibraryBackgrounds() {
  const res: GetUserLibraryAssetsByTypeQueryResult = yield ApolloClient.query({
    fetchPolicy: 'network-only',
    query: GetUserLibraryAssetsByTypeDocument,
    variables: {
      type: 'background'
    }
  })

  const backgrounds = res.data?.userLibraryAssetsByType

  if (backgrounds && backgrounds.length > 0) {
    yield put(
      setUserLibraryBackgrounds(backgrounds.map(deserializeAssetDocument))
    )
  } else {
    yield put(setUserLibraryBackgrounds([]))
  }
}

// Create any type of asset
function* handleCreateUserLibraryAsset({
  payload
}: PayloadAction<CreateUserLibraryAssetRequest>) {
  yield ApolloClient.mutate({
    mutation: CreateUserLibraryAssetDocument,
    variables: {
      name: payload.name.trim(),
      type: payload.type,
      document: JSON.stringify({
        ...payload.document,
        ...(payload.type === 'material' &&
          Context.Engine?.getMaterialProperties())
      })
    }
  })

  // Refetch based on type
  if (payload.type === 'material') {
    yield put(fetchUserLibraryMaterials())
    return
  }

  if (payload.type === 'background') {
    yield put(fetchUserLibraryBackgrounds())
    return
  }

  yield put(fetchAllUserLibraryAssets())
}

function* handleApplyUserLibraryMaterialToSelection({
  payload: document
}: PayloadAction<UserLibraryMaterial['document']>) {
  const sceneState: SceneState = yield select((state: RootState) => state.scene)

  for (const materialProperty in document) {
    console.log(
      `[REACT]: Setting ${materialProperty} to ${document[materialProperty]}`
    )

    if (
      sceneState[materialProperty as keyof SceneState] !==
      document[materialProperty]
    ) {
      yield putResolve(
        setPropertyState({
          key: materialProperty,
          value: document[materialProperty]
        } as PropertyPayload)
      )
    }
  }
}

function* handleApplyUserLibraryBackground({
  payload: document
}: PayloadAction<UserLibraryBackground['document']>) {
  const sceneState: SceneState = yield select((state: RootState) => state.scene)

  for (const backgroundProperty in document) {
    if (
      sceneState[backgroundProperty as keyof SceneState] !==
      document[backgroundProperty]
    ) {
      console.log(
        `[REACT]: Setting ${backgroundProperty} to ${document[backgroundProperty]}`
      )
      yield putResolve(
        setPropertyState({
          key: backgroundProperty,
          value: document[backgroundProperty]
        } as PropertyPayload)
      )
    }
  }
}

// Archive an asset
function* handleArchiveUserLibraryAsset({
  payload
}: PayloadAction<ArchiveUserLibraryAssetRequest>) {
  yield ApolloClient.mutate({
    mutation: ArchiveUserLibraryAssetDocument,
    variables: {
      assetUuid: payload.assetUuid
    }
  })

  // Refetch based on type
  if (payload.type === 'material') {
    yield put(fetchUserLibraryMaterials())
    return
  }

  if (payload.type === 'background') {
    yield put(fetchUserLibraryBackgrounds())
    return
  }

  yield put(fetchAllUserLibraryAssets())
}

export default function* userLibrarySaga() {
  yield all([
    takeEvery(fetchAllUserLibraryAssets.type, handleFetchAllUserLibraryAssets),
    takeEvery(fetchUserLibraryMaterials.type, handleFetchUserLibraryMaterials),
    takeEvery(
      fetchUserLibraryBackgrounds.type,
      handleFetchUserLibraryBackgrounds
    ),
    takeEvery(createUserLibraryAsset.type, handleCreateUserLibraryAsset),
    takeEvery(archiveUserLibraryAsset, handleArchiveUserLibraryAsset),
    takeEvery(
      applyUserLibraryMaterialToSelection.type,
      handleApplyUserLibraryMaterialToSelection
    ),
    takeEvery(applyUserLibraryBackground.type, handleApplyUserLibraryBackground)
  ])
}
