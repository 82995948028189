import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { project, ProjectState } from '@store/slices/projectSlice'
import { bindActionCreators } from '@reduxjs/toolkit'

function totalDrawersOpen(state: ProjectState) {
  if (!!state.selectedRightDrawer && state.showSceneLayersPanel) {
    return 2
  }

  if (
    (!!state.selectedRightDrawer && !state.showSceneLayersPanel) ||
    (!state.selectedRightDrawer && state.showSceneLayersPanel)
  ) {
    return 1
  }

  return 0
}

export const useProject = () => {
  const state = useSelector((state: RootState) => state.project)
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(project.actions, dispatch)

  return {
    ...state,
    ...boundedActions,
    totalDrawersOpen: totalDrawersOpen(state)
  }
}

export type ProjectStateProperties = ReturnType<typeof useProject>
