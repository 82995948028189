import { useEffect, useReducer } from 'react'
import {
  TConverter,
  TPropertyBindings,
  TMutablePropertyBindings
} from './bindings'

/**
 * TPropertyBindingsProvider is a function providing a c++ property bindings on demand
 * Note: the API may be quite complicated due to the possible handing of undefined states
 * as the wasm module is not ready yet to be used in the early stage of processing the
 * react components
 * This will require a cleanup/simplification when this issue is fixed
 */

type TPropertyBindingsProvider<T> = () => TPropertyBindings<T> | undefined

class ObservationState {
  public cppObserver = 0
  public mounted = false
}

function observe<T>(
  state: ObservationState,
  propertyBindingsProvider: TPropertyBindingsProvider<T>,
  forceUpdate: any
): void {
  if (state.cppObserver != 0) {
    // already observing
    return
  }

  const bindings = propertyBindingsProvider()
  if (bindings) {
    state.cppObserver = bindings.observe(() => {
      if (state.mounted) {
        forceUpdate()
      }
    })
  }
}

function unobserve<T>(
  state: ObservationState,
  propertyBindingsProvider: TPropertyBindingsProvider<T>
): void {
  if (state.cppObserver != 0) {
    const bindings = propertyBindingsProvider()
    bindings?.unobserve(state.cppObserver)
    state.cppObserver = 0
  }
}

/**
 * useProperty gives access to a C++ property in a React environment
 * it takes care of properly modifying the React Component's state
 * when we receive a change notification from the C++ layer
 *
 * Properties are by design READ-ONLY.
 * Caller of useProperty gets the value of the property but cannot modify it.
 *
 * @param propertyBindingsProvider function that returns the c++ property binding
 * @param converter if defined, provide a way to convert back and forth TS and JS values
 * @returns the property value or undefined if wasm module not initialized yet
 */
export function useProperty<T, TBound = T>(
  propertyBindingsProvider: TPropertyBindingsProvider<TBound>,
  converter: TConverter<T, TBound> | undefined = undefined
): T | undefined {
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const state = new ObservationState()

  useEffect(() => {
    state.mounted = true
    observe<TBound>(state, propertyBindingsProvider, forceUpdate)

    return () => {
      unobserve<TBound>(state, propertyBindingsProvider)
      state.mounted = false
    }
  })

  const inValue: TBound | undefined = propertyBindingsProvider()?.get()
  if (inValue === undefined) {
    return undefined
  }

  if (converter !== undefined) {
    const v = inValue as TBound
    return converter.TFromTBound(v)
  } else {
    return inValue as T
  }
}

export type TMutableProperty<T> = [() => T | undefined, (v: T) => void]
export type TMutablePropertyFunction<T> = () => TMutableProperty<T>

/**
 * Similar to useProperty, useMutableProperty gives also access to a way to change the property's value.
 *
 * @param propertyBindingsProvider function that returns the c++ property binding
 * @param converter if defined, provide a way to convert back and forth TS and JS values
 * @returns an array of 2 functions: the getter, the setter
 */
export function useMutableProperty<T, TBound = T>(
  propertyBindingsProvider: TPropertyBindingsProvider<TBound>,
  converter: TConverter<T, TBound> | undefined = undefined
) {
  const prop: TMutableProperty<T> = [
    //get
    () => {
      return useProperty<T, TBound>(propertyBindingsProvider, converter)
    },

    //set
    (v: T) => {
      const prop = propertyBindingsProvider()
      if (!prop) {
        return
      }

      let valueToSet: TBound

      if (converter) {
        valueToSet = converter.TBoundFromT(v)
      } else {
        valueToSet = v as unknown as TBound
      }

      const mutableProp = prop as TMutablePropertyBindings<TBound>
      mutableProp.set(valueToSet)
    }
  ]

  return prop
}
