import { useAuth } from '@hooks/useAuth'
import { useEffect } from 'react'
import {
  redirectToBetaAuthorizationStep,
  shouldBlockPageWhileCheckingBetaAuthorization,
  shouldCurrentPathRequireBetaAuthorization
} from './path_authorization'

function RequireBetaAuthorization({
  children
}: {
  children: React.ReactElement
}) {
  const auth = useAuth()

  const isBetaAuthorizationRequired =
    shouldCurrentPathRequireBetaAuthorization(auth)

  const isPageBlockedWhileCheckingBetaAuthorization: boolean =
    shouldBlockPageWhileCheckingBetaAuthorization(auth)

  useEffect(() => {
    if (isBetaAuthorizationRequired) {
      redirectToBetaAuthorizationStep(window.location.pathname)
    }
  }, [window.location.pathname, isBetaAuthorizationRequired])

  return isBetaAuthorizationRequired ||
    isPageBlockedWhileCheckingBetaAuthorization ? null : (
    <>{children}</>
  )
}

export default RequireBetaAuthorization
