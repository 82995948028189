import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicMenuDivider = dynamic(
	async () =>
		await import('@swc-react/menu').then(({ MenuDivider }) => MenuDivider),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const MenuDivider: typeof DynamicMenuDivider = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/menu/sp-menu-divider.js')
	}, [])

	return <DynamicMenuDivider {...props} />
}
