import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicButton = dynamic(
	async () => await import('@swc-react/button').then(({ Button }) => Button),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Button: typeof DynamicButton = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/button/sp-button.js')
	}, [])

	return <DynamicButton {...props} />
}
