import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicBadge = dynamic(
	async () =>
		await import('@swc-react/badge').then(
			({ Badge }) => Badge
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Badge: typeof DynamicBadge = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/badge/sp-badge.js')
	}, [])

	return <DynamicBadge {...props} />
}