export enum Cursor {
  Arrow = 0,
  Move = 1,
  Rotate = 2,
  Scale = 3,
  Translate = 4,
  PanReady = 5,
  Pan = 6,
  Zoom = 7,
  ZoomIn = 8,
  ZoomOut = 9,
  Orbit = 10,
  OrbitYaw = 11,
  Drag = 12,
  DirectSelect = 13,
  AddPrimitive = 14,
  Dragging = 15,
  Translating = 16,
  Scaling = 17,
  Crosshair = 18
}

enum CursorSource {
  builtin,
  url
}

class CursorDescription {
  constructor(name: string, source: CursorSource) {
    this._name = name
    this._source = source
  }

  url(): string {
    return this._source === CursorSource.url
      ? "url('/gizmo-cursor-" + this._name + ".svg') 0 0, auto"
      : this._name
  }

  private _name: string
  private _source: CursorSource
}

export class CursorRegistry {
  public constructor() {
    this._cursorToDescription = new Map<Cursor, CursorDescription>()

    // TODO: please remove builtins with custom url() cursors

    this.add(Cursor.Arrow, 'select', CursorSource.url)
    this.add(Cursor.DirectSelect, 'direct-select', CursorSource.url)
    this.add(Cursor.Move, 'select', CursorSource.url)
    this.add(Cursor.Rotate, 'rotate', CursorSource.url)
    this.add(Cursor.Scale, 'scale', CursorSource.url)
    this.add(Cursor.Translate, 'select', CursorSource.url)

    // @todo: have two different pointers for all types
    // or simply remove grabbing state
    this.add(Cursor.PanReady, 'pan', CursorSource.url)
    this.add(Cursor.Pan, 'pan-ready', CursorSource.url)

    this.add(Cursor.Zoom, 'zoom', CursorSource.url)
    this.add(Cursor.ZoomIn, 'zoom-in', CursorSource.url)
    this.add(Cursor.ZoomOut, 'zoom-out', CursorSource.url)
    this.add(Cursor.Orbit, 'orbit', CursorSource.url)
    this.add(Cursor.OrbitYaw, 'orbit-yaw', CursorSource.url)
    this.add(Cursor.Drag, 'select', CursorSource.url)

    this.add(Cursor.AddPrimitive, 'add-primitive', CursorSource.url)

    this.add(Cursor.Dragging, 'select', CursorSource.url)
    this.add(Cursor.Translating, 'select', CursorSource.url)
    this.add(Cursor.Scaling, 'select', CursorSource.url)

    this.add(Cursor.Crosshair, 'crosshair', CursorSource.builtin)
  }

  private add(cursor: Cursor, name: string, source: CursorSource): void {
    const descr = new CursorDescription(name, source)
    this._cursorToDescription.set(cursor, descr)
  }

  public urlForCursor(cursor: Cursor): string {
    const descr = this._cursorToDescription.get(cursor)
    if (descr != undefined) {
      return descr.url()
    } else {
      return 'default'
    }
  }

  private _cursorToDescription: Map<Cursor, CursorDescription>
}
