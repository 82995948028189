import { FrameEventPayload } from '@services/engine/types'
import { setPropertiesState } from '@store/slices/sceneSlice'
import { put } from 'redux-saga/effects'

export default function* setFrame({
  enabled,
  position,
  size
}: FrameEventPayload) {
  yield put(
    setPropertiesState({
      framePosition: position
    })
  )
}
