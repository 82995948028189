import React, { useContext, FC, useState } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, Icon, Tabs, Tab, TabPanel } from 'ui'
import { ThemeContext } from '@contexts/ThemeContext'
import { CustomLink } from '@components/navigation/CustomLink'
import { UploadProjectButton } from '@components/project/UploadProjectButton'
import AppLogo from '@components/appLogo/AppLogo'
import CreateProjectButton from '@components/project/CreateProjectButton'
import JoinDiscordButton from '@components/navigation/JoinDiscordButton'
import UserProfile from '@components/authentication/UserProfile'
import styles from '@styles/components/AppBar.module.scss'
import Light from '@spectrum-icons/workflow/Light'
import Moon from '@spectrum-icons/workflow/Moon'
import HamburgerMenuIcon from '/public/s2_icon_menu_hamburger.svg'

const RouteTabs: readonly { name: string; href: string }[] = [
  {
    name: 'Discover',
    href: '/discover'
  },
  {
    name: 'My Projects',
    href: '/my-projects'
  }
] as const

const AppBar: FC = () => {
  const { route, push } = useRouter()
  const { isDarkMode, handleThemeToggle } = useContext(ThemeContext)
  const [navWidth, setNavWidth] = useState<number>(0)
  const openNav = () => setNavWidth(260)
  const closeNav = () => setNavWidth(0)
  const hiddenFileInputRef = React.useRef<HTMLInputElement>(null)
  const flags = useFlags()

  function handleTabChange(event: any) {
    const selectedTabValue = event.target?.selected
    const isValidRouteTab = RouteTabs.some(tab => tab.href === selectedTabValue)
    if (isValidRouteTab) {
      push(selectedTabValue)
    }
  }

  function onLogoClick(event: React.MouseEvent) {
    event.preventDefault()
    push('/')
  }

  return (
    <div className={styles['container']}>
      <div className={styles['app-bar']}>
        <div className={styles['left-content']}>
          <div className={styles['hamburger-menu']}>
            <ActionButton aria-label="Menu" quiet onClick={openNav}>
              <Icon slot="icon" className="icon-m">
                <HamburgerMenuIcon />
              </Icon>
            </ActionButton>
          </div>
          <div className={styles['logo-wrapper']}>
            <AppLogo logo="adobe" onClick={onLogoClick} />
          </div>

          <Tabs
            selected={route}
            quiet
            aria-label="Links to Discover and My Projects Page"
            change={handleTabChange}>
            {RouteTabs.map(item => (
              <Tab
                role="tab"
                key={item.href}
                value={item.href}
                className={styles['tab']}>
                <CustomLink href={item.href}>{item.name}</CustomLink>
              </Tab>
            ))}
            <TabPanel value="/discover"></TabPanel>
            <TabPanel value="/my-projects"></TabPanel>
          </Tabs>
        </div>
        <div className={styles['right-wrapper']}>
          <div className={styles['action-buttons']}>
            {flags['base-tf-ui-discord-link'] && <JoinDiscordButton />}
            {flags['debug-tf-fx-project-json'] && <UploadProjectButton />}
            <CreateProjectButton />
          </div>
          <UserProfile themeColor={{ trigger: 'light', dialog: 'light' }} />
          {flags['base-tf-ui-light-dark-theme-switcher'] && (
            <ActionButton
              aria-label="Theme Switcher"
              quiet
              onClick={() => handleThemeToggle()}>
              {isDarkMode ? <Light size="S" /> : <Moon size="S" />}
            </ActionButton>
          )}
        </div>
      </div>

      <div>
        <div
          className={cn(styles['side-nav-overlay'], {
            [styles['side-nav-overlay__show']]: navWidth > 0
          })}
          onClick={closeNav}></div>
        <div className={styles['side-nav']} style={{ width: navWidth }}>
          <div className={styles['logo-wrapper']}>
            <AppLogo logo="adobe" onClick={onLogoClick} />
          </div>
          <div className={styles['side-nav__content']}>
            <div className={styles['side-nav__tabs']}>
              {RouteTabs.map(item => (
                <CustomLink key={item.href} href={item.href}>
                  <div className={styles['side-nav__tab']} onClick={closeNav}>
                    {item.name}
                  </div>
                </CustomLink>
              ))}
            </div>

            <div className={styles['side-nav__action-buttons']}>
              <div>
                <CreateProjectButton />
              </div>
              <div>
                {flags['base-tf-ui-discord-link'] && <JoinDiscordButton />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppBar
