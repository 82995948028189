import { useEffect, useState } from 'react'
import { Size } from 'types/size'

export const useMaxSize = (
  containerElement: HTMLDivElement | null,
  aspectRatio: number
) => {
  const [size, setSize] = useState<Size | null>(null)

  useEffect(() => {
    if (!containerElement) return

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setSize(getMaxSize(entry.contentRect, aspectRatio))
      }
    })

    observer.observe(containerElement)

    return () => {
      observer.disconnect()
    }
  }, [containerElement, aspectRatio])

  return size
}

const getMaxSize = ({ height, width }: Size, aspectRatio: number): Size => {
  let maxHeight = height
  let maxWidth = width

  let calculatedWidth = height * aspectRatio
  if (calculatedWidth <= width) {
    maxWidth = calculatedWidth
  } else {
    maxHeight = width / aspectRatio
  }

  return {
    height: maxHeight,
    width: maxWidth
  }
}
