import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type RightDrawer =
  | 'Properties'
  | 'Community'
  | 'Chat'
  | 'Firefly'
  | 'Player'
  | 'Meet'
  | 'Views'
  | 'UserLibrary'
  | 'Layers'

export interface ProjectState {
  name: string | null
  isFeatured: boolean | null
  projectUuid: string | null
  ownerUserUuid: string | null
  selectedRightDrawer: RightDrawer | null
  documentChannelStatus: null | boolean
  documentSnapshotSuccess: boolean
  documentSnapshotTimestamp: number
  showSceneNavigator: boolean
  showOrbitControls: boolean
  selectedObjectPropertyTab: 'all' | 'color' | 'object' | 'shape'
  isLoading: boolean
  showRefreshDialog: boolean
  showTouchDeviceWarningDialog: boolean
  showWindowsGPUConfigInstructionDialog: boolean
  showSceneLayersPanel: boolean
  cursorOverlay: string
  showFireflyPopover: boolean
  isDownloadExportDialogOpen: boolean
  isShareExportDialogOpen: boolean
}

const initialState: ProjectState = {
  isFeatured: null,
  name: null,
  projectUuid: null,
  ownerUserUuid: null,
  selectedRightDrawer: 'Properties',
  documentChannelStatus: null,
  documentSnapshotSuccess: true,
  documentSnapshotTimestamp: Date.now(),
  showSceneNavigator: true,
  showOrbitControls: true,
  selectedObjectPropertyTab: 'all',
  isLoading: true,
  showRefreshDialog: false,
  showTouchDeviceWarningDialog: false,
  showWindowsGPUConfigInstructionDialog: false,
  showSceneLayersPanel: false,
  cursorOverlay: '',
  showFireflyPopover: false,
  isDownloadExportDialogOpen: false,
  isShareExportDialogOpen: false
}

export const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setIsFeatured: (
      state,
      { payload }: PayloadAction<ProjectState['isFeatured']>
    ) => {
      state.isFeatured = payload
    },
    setName: (state, { payload }: PayloadAction<ProjectState['name']>) => {
      state.name = payload
    },
    setProjectUuid: (
      state,
      { payload }: PayloadAction<ProjectState['projectUuid']>
    ) => {
      state.projectUuid = payload
    },
    setOwnerUserUuid: (
      state,
      { payload }: PayloadAction<ProjectState['ownerUserUuid']>
    ) => {
      state.ownerUserUuid = payload
    },
    setSelectedRightDrawer: (
      state,
      { payload }: PayloadAction<ProjectState['selectedRightDrawer']>
    ) => {
      state.selectedRightDrawer = payload
    },
    loadProject: (
      state,
      { payload }: PayloadAction<{ requireAuth: boolean }>
    ) => {},
    projectLoaded: () => {},
    setDocumentChannelStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.documentChannelStatus = payload
    },
    setDocumentSnapshotSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.documentSnapshotSuccess = payload
    },
    setDocumentSnapshotTimestamp: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.documentSnapshotTimestamp = payload
    },
    setShowSceneNavigator: (
      state,
      { payload }: PayloadAction<ProjectState['showSceneNavigator']>
    ) => {
      state.showSceneNavigator = payload
    },
    setShowOrbitControls: (
      state,
      { payload }: PayloadAction<ProjectState['showOrbitControls']>
    ) => {
      state.showOrbitControls = payload
    },
    setSelectedObjectPropertyTab: (
      state,
      { payload }: PayloadAction<ProjectState['selectedObjectPropertyTab']>
    ) => {
      state.selectedObjectPropertyTab = payload
    },
    resetState: state => {
      state.isFeatured = null
      state.name = null
      state.projectUuid = null
      state.ownerUserUuid = null
      state.documentChannelStatus = null
      state.documentSnapshotSuccess = true
      state.documentSnapshotTimestamp = Date.now()
      state.showSceneNavigator = true
      state.isLoading = true
      state.selectedObjectPropertyTab = 'all'
      state.selectedRightDrawer = 'Properties'
      state.showFireflyPopover = false
    },
    setIsLoading: (
      state,
      { payload }: PayloadAction<ProjectState['isLoading']>
    ) => {
      state.isLoading = payload
    },
    setShowRefreshModal: (
      state,
      { payload }: PayloadAction<ProjectState['showRefreshDialog']>
    ) => {
      state.showRefreshDialog = payload
    },
    setShowTouchDeviceWarningDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showTouchDeviceWarningDialog']>
    ) => {
      state.showTouchDeviceWarningDialog = payload
    },
    setShowWindowsGPUConfigInstructionDialog: (
      state,
      {
        payload
      }: PayloadAction<ProjectState['showWindowsGPUConfigInstructionDialog']>
    ) => {
      state.showWindowsGPUConfigInstructionDialog = payload
    },
    setShowSceneLayersPanel: (
      state,
      { payload }: PayloadAction<ProjectState['showSceneLayersPanel']>
    ) => {
      state.showSceneLayersPanel = payload
    },
    setCursorOverlay: (state, { payload }: PayloadAction<string>) => {
      state.cursorOverlay = payload
    },
    setShowFireflyPopover: (
      state,
      { payload }: PayloadAction<ProjectState['showFireflyPopover']>
    ) => {
      state.showFireflyPopover = payload
    },
    setIsDownloadExportDialogOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isDownloadExportDialogOpen']>
    ) => {
      state.isDownloadExportDialogOpen = payload
    },
    setIsShareExportDialogOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isShareExportDialogOpen']>
    ) => {
      state.isShareExportDialogOpen = payload
    },
    persistProjectDocumentFrameSize: (
      state,
      { payload }: PayloadAction<{ width: number; height: number }>
    ) => {}
  }
})

export const {
  setIsFeatured,
  setName,
  setProjectUuid,
  setOwnerUserUuid,
  loadProject,
  projectLoaded,
  setSelectedRightDrawer,
  setDocumentChannelStatus,
  setDocumentSnapshotSuccess,
  setDocumentSnapshotTimestamp,
  setShowSceneNavigator,
  setShowOrbitControls,
  setSelectedObjectPropertyTab,
  resetState,
  setIsLoading,
  setShowRefreshModal,
  setShowTouchDeviceWarningDialog,
  setShowWindowsGPUConfigInstructionDialog,
  setShowSceneLayersPanel,
  setCursorOverlay,
  setShowFireflyPopover,
  persistProjectDocumentFrameSize
} = project.actions
export default project.reducer
