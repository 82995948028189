import React, { ComponentProps, FC } from 'react'
import { useRouter } from 'next/router'
import { Button } from 'ui'
import { useAuth } from '@hooks/useAuth'
import { useCreateProjectMutation } from '@store/graphql/__generated__/schema'
import { uniqueNamesGenerator, colors } from 'unique-names-generator'
import { SpIconAdd } from 'workflow-icons'

interface Props {
  className?: string
  label?: string
  hideIcon?: boolean
  variant?: ComponentProps<typeof Button>['variant']
}

const CreateProjectButton: FC<Props> = ({
  className,
  variant,
  label,
  hideIcon
}) => {
  const router = useRouter()

  const { localUser } = useAuth()

  const [createProjectMutation, { loading }] = useCreateProjectMutation({
    update: (_, mutationResult) => {
      const uuid = mutationResult.data?.createProject?.uuid
      if (uuid) router.push(`/studio/${uuid}`)
    }
  })

  function createProject() {
    createProjectMutation({
      variables: {
        name: uniqueNamesGenerator({
          dictionaries: [colors]
        }),
        tags: ['lifestyle', 'tech']
      }
    })
  }

  return (
    <Button
      className={className}
      variant={variant || 'cta'}
      size={variant ? 'l' : 'm'}
      pending={!localUser || loading}
      onClick={createProject}
      style={{
        whiteSpace: 'nowrap'
      }}>
      {!hideIcon && <SpIconAdd slot="icon" />}
      {label || 'Create a new design'}
    </Button>
  )
}

export default CreateProjectButton
