import { ActionCreatorWithPayload, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import authReducer from '@store/slices/authSlice'
import sceneReducer from '@store/slices/sceneSlice'
import syncReducer from '@store/slices/syncSlice'
import projectReducer from '@store/slices/projectSlice'
import chimeReducer from '@store/slices/chimeSlice'
import RootSaga from '@store/middleware'
import userLibraryReducer from '@store/slices/userLibrarySlice'
import fireflyReducer from '@store/slices/fireflySlice'
import monitoringReducer from '@concerns/monitoring/store/monitoringSlice'

const sagaMiddleware = createSagaMiddleware()

// const loggerMiddleware = createLogger()
// const middleware = [loggerMiddleware, sagaMiddleware]
const middleware = [sagaMiddleware]

export const RootStore = configureStore({
  reducer: {
    auth: authReducer,
    scene: sceneReducer,
    sync: syncReducer,
    project: projectReducer,
    chime: chimeReducer,
    userLibrary: userLibraryReducer,
    monitoring: monitoringReducer,
    firefly: fireflyReducer
  },
  middleware
})

sagaMiddleware.run(RootSaga)

export type RootState = ReturnType<typeof RootStore.getState>
export type StoreDispatch = typeof RootStore.dispatch
export type PayloadType<T extends ActionCreatorWithPayload<any>> =
  Parameters<T>[0]
