import {
  DunamisClientOptions,
  DunamisServers,
  ExponentialBackoffOpts,
  IngestBody,
  IngestEnvironment,
  IngestEvent,
  RecordEventSDMData
} from './dunamis.types'

import { SDMEventData, SDMCustomData } from './sdm.types'
import RootPackage from '../../../../../../package.json'

export class DunamisClient {
  private opts: Required<DunamisClientOptions>
  private environment: IngestEnvironment
  private batchWindowStarted = false
  private pendingEvents: IngestEvent[] = []

  constructor(opts: DunamisClientOptions) {
    this.opts = {
      server:
        process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd'
          ? DunamisServers.Production
          : DunamisServers.Stage,
      batchPeriodMs: 100,
      workflow: 'APP',
      category: 'WEB',
      sourceName: 'Project Neo',
      sourceVersion: RootPackage.version,
      ...opts
    }

    this.environment =
      this.opts.server === DunamisServers.Production ? 'production' : 'stage'
  }

  private waitFor(ms: number) {
    return new Promise<void>(res => setTimeout(res, ms))
  }

  private async exponentialBackoff(
    f: () => Promise<any>,
    opts?: ExponentialBackoffOpts
  ): Promise<any> {
    const optsWithDefaults = {
      retries: 5,
      initialDelayMs: 100,
      ...opts
    }
    let delay = optsWithDefaults.initialDelayMs
    for (let i = 0; i < optsWithDefaults.retries; i++) {
      try {
        return await f()
      } catch (e) {
        if (i === optsWithDefaults.retries - 1) {
          console.error(e, `error occured, max retries reached, aborting`)
          throw e
        }
        console.warn(e, `error occured, retrying in ${delay}ms`)
      }
      await this.waitFor(delay)
      delay *= 2
    }
  }

  private async batchSendEvents() {
    if (!this.pendingEvents.length) return
    if (this.batchWindowStarted) return

    this.batchWindowStarted = true
    await this.waitFor(this.opts.batchPeriodMs)
    this.batchWindowStarted = false

    const events = [...this.pendingEvents]
    this.pendingEvents = []

    await this.exponentialBackoff(async () => this.httpIngest({ events }), {
      initialDelayMs: 100,
      retries: 3
    })
  }

  private async httpIngest(body: IngestBody) {
    const req = await fetch(`${this.opts.server}/ingest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.opts.apiKey
      },
      body: JSON.stringify(body)
    })
    if (!req.ok) {
      throw Error(
        `cannot ingest these events, status=${
          req.status
        }, body=${await req.text()}`
      )
    }
  }

  async recordEvent(evt: RecordEventSDMData & SDMCustomData) {
    try {
      await this.recordEventAsync(evt)
    } catch (e) {
      console.error(e, `error occured, cannot send events`)
    }
  }

  recordEventAsync(evt: RecordEventSDMData & SDMCustomData) {
    const now = new Date()

    const filledEvt: SDMEventData = {
      'event.dts_start': now,
      'event.workflow': this.opts.workflow,
      'event.category': this.opts.category,
      'source.name': this.opts.sourceName,
      'source.version': this.opts.sourceVersion,
      'source.platform': 'todo',
      ...evt
    }

    this.pendingEvents.push({
      data: filledEvt,
      ingesttype: 'dunamis',
      project: this.opts.project,
      environment: this.environment,
      time: now
    })

    return this.batchSendEvents()
  }
}
