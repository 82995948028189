import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTabPanel = dynamic(
  async () => await import('@swc-react/tabs').then(({ TabPanel }) => TabPanel),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const TabPanel: typeof DynamicTabPanel = props => {
  useEffect(() => {
    import('@spectrum-web-components/tabs/sp-tab-panel.js')
  }, [])

  return <DynamicTabPanel {...props} />
}
