import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicToast = dynamic(
	async () =>
		await import('@swc-react/toast').then(
			({ Toast }) => Toast
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Toast: typeof DynamicToast = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/toast/sp-toast.js')
	}, [])

	return <DynamicToast {...props} />
}