import { SDMCoreCategory, SDMEventData } from './sdm.types'

export type IngestEnvironment = 'production' | 'stage'

export interface IngestEvent {
  data: SDMEventData
  ingesttype: 'dunamis'
  project: string
  environment: IngestEnvironment
  time: Date
}

export interface IngestBody {
  events: IngestEvent[]
}

export enum DunamisServers {
  Production = 'https://cc-api-data.adobe.io',
  Stage = 'https://cc-api-data-stage.adobe.io'
}

export interface DunamisClientOptions {
  // Mandatory
  apiKey: string
  project: string

  server?: DunamisServers

  /**
   * @default 100
   */
  batchPeriodMs?: number

  /**
   * @default 'Unknown'
   */
  workflow?: string

  /**
   * @default 'SERVICE'
   */
  category?: SDMCoreCategory

  /**
   * @default 'ts-utils'
   */
  sourceName?: string

  /**
   * @default '1.0.0'
   */
  sourceVersion?: string
}

export type RecordEventSDMData = Partial<SDMEventData> &
  // required fields
  Pick<SDMEventData, 'event.subcategory' | 'event.type' | 'event.subtype'>

export interface ExponentialBackoffOpts {
  /**
   * Max retries
   */
  retries?: number

  /**
   * First wait period on first failure, in ms.
   */
  initialDelayMs?: number
}
