import { useAuth } from '@hooks/useAuth'
import styles from '@styles/components/Legal.module.scss'
import { PropsWithChildren } from 'react'
import cn from 'classnames'

const getCopyright = () => {
  const currYear = new Date().getFullYear()
  if (currYear === 2024) {
    return 'Copyright © 2024 Adobe. All Rights Reserved.'
  } else {
    return `Copyright © 2024-${currYear.toString()} Adobe. All Rights Reserved.`
  }
}

function getLegalItems(): {
  text: string
  url?: string
  type: 'link' | 'plaintext'
}[] {
  const copyright = getCopyright()

  return [
    {
      text: copyright,
      type: 'plaintext'
    },
    {
      text: 'Privacy Policy',
      url: 'http://www.adobe.com/go/privacy_policy',
      type: 'link'
    },
    {
      text: 'Terms of Use',
      url: 'http://www.adobe.com/go/terms',
      type: 'link'
    },
    {
      text: 'Do not sell or share my personal information',
      url: 'http://www.adobe.com/go/ca-rights',
      type: 'link'
    }
  ]
}

type Props = {
  direction: 'row' | 'column'
}

export function Legal({ direction }: Props) {
  const { localUser } = useAuth()
  const legalItems = getLegalItems()

  return (
    <ul
      className={cn(
        direction === 'row' ? styles['links'] : styles['links__column']
      )}>
      {legalItems.map(({ text, url, type }, index) => {
        if (type === 'plaintext') {
          return (
            <ListItem key={index} direction={direction}>
              {text}
            </ListItem>
          )
        } else {
          return (
            <ListItem key={index} direction={direction}>
              <a target="_blank" href={url}>
                {text}
              </a>
            </ListItem>
          )
        }
      })}
    </ul>
  )
}

type ListItemProps = Props

function ListItem({ children, direction }: PropsWithChildren<ListItemProps>) {
  return (
    <li
      className={cn(
        direction === 'row' ? styles['link'] : styles['link__column']
      )}>
      {children}
    </li>
  )
}
