import React, { FC, ReactNode, useEffect } from 'react';
import dynamic from 'next/dynamic';

const DynamicTheme = dynamic(
  async () => await import('@swc-react/theme').then(({ Theme }) => Theme),
  {
    ssr: false,
    loading: () => <></>,
  }
);

interface Props {
  children: ReactNode;
  color?: 'light' | 'dark';
  theme?: 'spectrum' | 'express';
}

export const Theme: FC<Props> = ({
  color = 'light',
  theme = 'express',
  ...props
}) => {
  useEffect(() => {
    import('@spectrum-web-components/theme/src/express/themes.js');
    import('@spectrum-web-components/theme/express/scale-medium.js');
    import('@spectrum-web-components/theme/src/themes.js');
  }, []);

  return <DynamicTheme scale='medium' {...props} color={color} theme={theme} />;
};
