import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTabs = dynamic(
  async () => await import('@swc-react/tabs').then(({ Tabs }) => Tabs),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Tabs: typeof DynamicTabs = props => {
  useEffect(() => {
    import('@spectrum-web-components/tabs/sp-tabs.js')
  }, [])

  return <DynamicTabs {...props} />
}
