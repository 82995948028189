import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicSwitch = dynamic(
	async () => await import('@swc-react/switch').then(({ Switch }) => Switch),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Switch: typeof DynamicSwitch = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/switch/sp-switch.js')
	}, [])

	return <DynamicSwitch {...props} />
}
