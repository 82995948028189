import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicOverlayTrigger = dynamic(
	async () =>
		await import('@swc-react/overlay').then(
			({ OverlayTrigger }) => OverlayTrigger
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const OverlayTrigger: typeof DynamicOverlayTrigger = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/overlay/sync/overlay-trigger.js')
	}, [])

	return <DynamicOverlayTrigger {...props} />
}
