import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from '@styles/components/EmojiPicker.module.scss'

const EmojiReceiver = ({ emoji }: { emoji: string }) => {
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    setAnimating(!!emoji)
  }, [emoji])

  return animating ? (
    <div className={cn(styles['emoji-picker__animation'])}>
      <span>{emoji}</span>
    </div>
  ) : null
}

export default EmojiReceiver
