import React, { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import styles from '@styles/components/AnimatedTextInput.module.scss'
import { useSync } from '@hooks/useSync'

interface AnimatedTextInputProps {
  isFocused?: boolean
  readOnlyValue?: string
  onSubmit?: () => void
  style?: React.CSSProperties
}

const AnimatedTextInput: FC<AnimatedTextInputProps> = ({
  isFocused,
  readOnlyValue,
  onSubmit,
  style
}) => {
  const { sendLocalUserInputMessageValue, sendLocalUserSubmitMessageValue } =
    useSync()
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const minWidth = 30
  const lengthMultiplier = 5.84
  const inputWidth = inputValue.length * lengthMultiplier + minWidth
  const readOnlyValueWidth =
    readOnlyValue && readOnlyValue.length * lengthMultiplier + minWidth

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    e.stopPropagation()
  }

  useEffect(() => {
    sendLocalUserInputMessageValue({ input_message: inputValue })
  }, [inputValue])

  const handleSubmit = () => {
    sendLocalUserSubmitMessageValue({ submit_message: inputValue })
    setInputValue('')
    onSubmit?.()
  }

  const inputClass = cn(styles['animated-text-input__input'], {
    [styles['animated-text-input__input--read-only']]: !!readOnlyValue
  })

  return (
    <>
      <input
        ref={inputRef}
        className={inputClass}
        type="text"
        value={readOnlyValue || inputValue}
        onChange={handleInputChange}
        disabled={!!readOnlyValue}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            setInputValue('')
          }
          if (e.key === 'Enter') {
            handleSubmit()
          }
        }}
        style={{
          ...(style && style),
          width: `${readOnlyValue ? readOnlyValueWidth : inputWidth}px`
        }}
        autoFocus={isFocused}
      />
    </>
  )
}

export default AnimatedTextInput
