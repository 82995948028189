import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicMenuGroup = dynamic(
	async () =>
		await import('@swc-react/menu').then(({ MenuGroup }) => MenuGroup),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const MenuGroup: typeof DynamicMenuGroup = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/menu/sp-menu-group.js')
	}, [])

	return <DynamicMenuGroup {...props} />
}
