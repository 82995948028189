import { all, select, takeEvery } from 'redux-saga/effects'
import Context from '@store/middleware/context'
import { setAdobeAnalyticsInitialized } from '@concerns/monitoring/store/monitoringSlice'
import { RootState } from '@store/store'
import { setLocalUser } from '@store/slices/authSlice'

function* sendUserIdentifiers() {
  const { isAdobeAnalyticsInitialized } = yield select(
    (state: RootState) => state.monitoring
  )
  const { localUser } = yield select((state: RootState) => state.auth)

  if (localUser) {
    if (isAdobeAnalyticsInitialized) {
      Context.Analytics?.sendEvent({
        xdm: {
          _3di: {
            member_guid: localUser.adobeUserId,
            service_user_guid: localUser.uuid
          },
          identityMap: {
            member_guid: [{ id: localUser.adobeUserId }],
            service_user_guid: [{ id: localUser.uuid }]
          }
        }
      })
    }

    try {
      FS('setIdentity', {
        uid: localUser.uuid,
        properties: {
          displayName: localUser.adobeUserDisplayName,
          email: localUser.adobeUserEmail,
          adobeUserId: localUser.adobeUserId
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default function* monitoringSaga() {
  yield all([
    takeEvery(
      [setAdobeAnalyticsInitialized.type, setLocalUser.type],
      sendUserIdentifiers
    )
  ])
}
