import Context from '@store/middleware/context'
import { Cartesian, CCartesian } from './types'

/**
 * Global access to the WASM module where Engine Bindings
 * can be found.
 * @todo inject this data in the React component instead
 */
export function getWasmModule(): any {
  return Context.Engine?.getModule()
}

/**
 * BindingsContainer is storing a handle to the a set of bound functions
 * coming from WASM
 * It make sure that the WASM bindings object is allocated once on demand
 */

export type PostInitCallback<TBindings> = (bindings: TBindings) => void

export class BindingsContainer<TBindings> {
  private _bindings: TBindings | undefined
  private _exportedName: string
  private _postInitCallback: PostInitCallback<TBindings> | undefined = undefined

  constructor(
    exportedName: string,
    postInitCallback: PostInitCallback<TBindings> | undefined = undefined
  ) {
    this._exportedName = exportedName
    this._postInitCallback = postInitCallback
  }

  public get(): TBindings | undefined {
    if (this._bindings === undefined) {
      const module = getWasmModule()
      if (module) {
        this._bindings = module[this._exportedName].getInstance()
        if (this._bindings && this._postInitCallback) {
          this._postInitCallback(this._bindings)
          this._postInitCallback = undefined
        }
      }
    }

    return this._bindings
  }
}

/**
 * Technically all JS objects backed a WASM binding has
 * to be manually disposed because of manual memory management
 * required by C++
 * It's JS code's responsibility to manually call delete on such
 * objects to make sure the memory is properly released
 * Embind automatically exposes this delete method
 * @todo: investigate 'using' keyword introduced by typescript 5.2
 * to kind of emulate RAII on JS side
 */
export interface IDeletable {
  delete(): void
}

/**
 * Some C++ types are naturally converted by Embind (eg (int, float, double) <=> number, bool <=> boolean)
 * When developer when to expose a more complex type (like a compound struct) and still enforce strong typing
 * in both TS and C++ world, Converter objects are use to perform the type conversion between the 2 worlds
 * TConverter is the interface for these objects.
 *
 * @param T : the type as publicly exposed to the TS code
 * @param TBound: the type that comes directly from the Embind binding
 */
export interface TConverter<T, TBound> {
  TBoundFromT(v: T): TBound
  TFromTBound(v: TBound): T
}

/**
 * Vec2Bindings exposes c++ vec2
 */
export declare class Vec2Bindings implements IDeletable {
  constructor()
  constructor(x: number, y: number)

  x(): number
  y(): number

  delete(): void
}

/**
 * Vec3Bindings exposes c++ vec3
 */
export declare class Vec3Bindings implements IDeletable {
  constructor()
  constructor(x: number, y: number, z: number)

  x(): number
  y(): number
  z(): number

  delete(): void
}

export function cartesianFromVec3Bindings(v: Vec3Bindings): Cartesian {
  return new CCartesian(v.x(), v.y(), v.z())
}

/**
 * CameraPoseBindings exposes C++ SphericalPose
 */
export declare class CameraPoseBindings implements IDeletable {
  constructor()

  getTarget(): Vec3Bindings
  setTarget(t: Vec3Bindings): void

  getRadius(): number
  setRadius(r: number): void

  getPhi(): number
  setPhi(p: number): void

  getTheta(): number
  setTheta(t: number): void

  delete(): void
}

/**
 * PropertyBindings is the API of exposed by Embind to manipulate C++ Property
 */

type ObserverCallback = () => void
export interface PropertyBindings {
  observe(cb: ObserverCallback): number
  unobserve(observer: number): boolean
}
export interface TPropertyBindings<T> extends PropertyBindings {
  get(): T
}

export interface TMutablePropertyBindings<T> extends TPropertyBindings<T> {
  set(v: T): void
}

/**
 * Bool
 */
export interface BoolPropertyBindings extends TPropertyBindings<boolean> {}

export interface MutableBoolPropertyBindings
  extends BoolPropertyBindings,
    TMutablePropertyBindings<boolean> {}

/**
 * Number
 */
export interface NumberPropertyBindings extends TPropertyBindings<number> {}

export interface MutableNumberPropertyBindings
  extends NumberPropertyBindings,
    TMutablePropertyBindings<number> {}

/**
 * string
 */
export interface StringPropertyBindings extends TPropertyBindings<string> {}

export interface MutableStringPropertyBindings
  extends StringPropertyBindings,
    TMutablePropertyBindings<string> {}

/**
 * signal
 */
export interface SignalPropertyBindings extends TPropertyBindings<undefined> {}

export interface ArrayBindings<T> extends IDeletable {
  size(): number
  get(index: number): T
}

export interface MutableArrayBindings<T> extends ArrayBindings<T> {
  resize(size: number): void
  set(index: number, value: T): void
  push_back(value: T): void
}
