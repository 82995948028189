import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { Channel } from 'phoenix'
import { eventChannel, EventChannel } from 'redux-saga'
import { RootState } from '@store/store'
import Context from '@store/middleware/context'
import { engineLoaded, exportCapture } from '@store/slices/sceneSlice'
import {
  persistProjectDocumentFrameSize,
  setDocumentChannelStatus,
  setDocumentSnapshotSuccess,
  setDocumentSnapshotTimestamp
} from '@store/slices/projectSlice'
import {
  createSocket,
  socketCreated
} from '@store/middleware/socket/socketSaga'
import {
  parseValidEngineJson,
  traceInvalidJsonError
} from '@services/engine/utils'
import ApolloClient from '@store/graphql/client'
import {
  UpdateProjectDocument,
  UpdateProjectMutationResult
} from '@store/graphql/__generated__/schema'

const documentState: PayloadAction<any> = {
  type: 'document_state',
  payload: {}
}

// Original value is null which indicates it's state has not been set yet
export function isDocumentUpdatable(
  localUser: RootState['auth']['localUser'],
  isProjectFeatured: RootState['project']['isFeatured'],
  projectOwnerUserUuid: RootState['project']['ownerUserUuid']
): boolean {
  if (
    localUser === null ||
    projectOwnerUserUuid === null ||
    isProjectFeatured === null
  ) {
    return false
  }

  if (localUser.uuid === projectOwnerUserUuid && isProjectFeatured === false) {
    return true
  }

  return false
}

function* handleEngineLoaded() {
  const projectUuid: string = yield select(
    (state: RootState) => state.project.projectUuid
  )

  try {
    yield fork(
      Context.Engine?.loadDocument,
      `${process.env.NEXT_PUBLIC_SERVICE_CORE_API_ENDPOINT}/projects/${projectUuid}/document`
    )

    const { auth, project }: RootState = yield select(
      (state: RootState) => state
    )

    const { localUser } = auth
    const { isFeatured, ownerUserUuid } = project

    if (
      Context.LDClient?.variation('base-tf-fx-snapshots') &&
      isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)
    ) {
      yield put(createSocket({ socketName: 'document', projectUuid }))

      yield take(
        action =>
          action.type === socketCreated.type && action.payload === 'document'
      )

      yield fork(handleSocketCreated)
    }
  } catch (e) {
    console.error(e)
  }
}

function* handleSocketCreated() {
  const { projectUuid } = yield select((state: RootState) => state.project)
  let engineState = yield select((state: RootState) => state.scene.engineState)

  // This is necessary to make sure that the document is  loaded before
  // saving it
  while (engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED') {
    engineState = yield select((state: RootState) => state.scene.engineState)
    yield delay(500)
  }

  yield fork(handleDocument, projectUuid)
}

function* handleDocument(projectUuid: string) {
  while (true) {
    try {
      yield delay(3000)
      const {
        scene: { engineState },
        project: { projectUuid: stateProjectUuid }
      }: RootState = yield select((state: RootState) => state)

      if (projectUuid !== stateProjectUuid) return

      // Extra check just in case the engine state changes after this task
      // has already been forked
      if (engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED') return

      yield fork(handleDocumentUpdate)
    } catch (e) {
      console.error(e)
    }
  }
}

function* handleDocumentUpdate() {
  yield fork(updateDocumentChannelConnectionStatus)
  yield fork(handleDocumentSnapshotUpdate)
  yield fork(handleDocumentThumbnailUpdate)
}

function* handleDocumentThumbnailUpdate() {
  yield delay(1500)
  const {
    project: {
      selectedRightDrawer,
      isDownloadExportDialogOpen,
      isShareExportDialogOpen
    }
  }: RootState = yield select((state: RootState) => state)

  // don't take screenshot when right drawer is open with firefly panel, when the download
  // export dialog is open, or when the share export dialog is open
  if (
    selectedRightDrawer === 'Firefly' ||
    isDownloadExportDialogOpen ||
    isShareExportDialogOpen
  ) {
    return
  }

  yield put(exportCapture({ format: 'screenshot', workflow: 'save' }))
}

function* updateDocumentChannelConnectionStatus() {
  const connected = Boolean(
    Context.document.channel?.state === 'joined' &&
      Context.document.socket?.isConnected()
  )

  yield put(setDocumentChannelStatus(connected))
}

function isEngineDocumentValidJson(engineDocument: string): boolean {
  try {
    parseValidEngineJson(engineDocument)
    return true
  } catch (err) {
    traceInvalidJsonError(
      'documentSaga.ts',
      'handleDocumentSnapshotUpdate',
      engineDocument,
      'Caught invalid JSON before updating document snapshot (snapshot not saved)'
    )
    return false
  }
}

function* handleDocumentSnapshotUpdate() {
  try {
    const engineDocument = Context.Engine?.getDocument()
    if (isEngineDocumentValidJson(engineDocument)) {
      Context.document.channel?.push('document_update', {
        document: engineDocument
      })
      if (Context.document.channel?.state === 'joined') {
        yield put(setDocumentSnapshotSuccess(true))
        yield put(setDocumentSnapshotTimestamp(Date.now()))
      }
    }
  } catch (e) {
    yield put(setDocumentSnapshotSuccess(false))
    console.error(e)
  }
}

// TODO: setup for use with document changes
function* watchDocumentSocketEvents(channel: Channel) {
  const socketEventChannel: EventChannel<PayloadAction> = yield call(
    createSocketEventChannel,
    channel
  )
  while (true) {
    try {
      const action: PayloadAction<any> = yield take(socketEventChannel)
    } catch (e) {
      console.error('documentSaga', e)
    }
  }
}

function createSocketEventChannel(phoenixChannel: Channel) {
  return eventChannel(emit => {
    phoenixChannel.on('document_state', (data: any) => {
      emit({
        type: documentState.type,
        payload: data
      })
    })

    return () => phoenixChannel.leave()
  })
}

function* handlePersistProjectDocumentFrameSize({
  payload: documentFrameSize
}: PayloadAction<{
  width: number
  height: number
}>) {
  const projectUuid: string = yield select(
    (state: RootState) => state.project.projectUuid
  )

  return ApolloClient.mutate<UpdateProjectMutationResult['data']>({
    mutation: UpdateProjectDocument,
    variables: {
      uuid: projectUuid,
      documentFrameSize
    }
  })
}

export default function* documentSaga() {
  yield all([
    takeEvery(engineLoaded.type, handleEngineLoaded),
    takeLatest(
      persistProjectDocumentFrameSize.type,
      handlePersistProjectDocumentFrameSize
    )
  ])
}
