import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicButtonGroup = dynamic(
	async () =>
		await import('@swc-react/button-group').then(
			({ ButtonGroup }) => ButtonGroup
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const ButtonGroup: typeof DynamicButtonGroup = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/button-group/sp-button-group.js')
	}, [])

	return <DynamicButtonGroup {...props} />
}