import Router from 'next/router'
import { MouseEvent, ReactElement } from 'react'

export const navigateWithHistory = (
  event: MouseEvent,
  href: string,
  forceRefresh: boolean = false
) => {
  event.preventDefault()
  if (forceRefresh) {
    window.location.href = href
  } else {
    Router.push(href, undefined, { shallow: false })
  }
}

export interface CustomLinkInterface {
  href: string
  children: ReactElement | string
  forceRefresh?: boolean
}

export const CustomLink = ({
  href,
  children,
  forceRefresh
}: CustomLinkInterface) => {
  return (
    <a
      href={href}
      onClick={event => navigateWithHistory(event, href, forceRefresh)}>
      {children}
    </a>
  )
}
