import React, { FC } from 'react'
import {
  ActionButton,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  View,
  Provider
} from '@adobe/react-spectrum'
import { useAuth } from '@hooks/useAuth'
import Avatar from '@components/avatar/Avatar'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { Legal } from '@components/legal/Legal'

// Can't import AdobeIms SSR Side to use to log out
const Logout = dynamic(async () => await import('./Logout'), {
  ssr: false,
  loading: () => <></>
})

// Can't import AdobeIms SSR Side to use to log in
const Login = dynamic(async () => await import('./AdobeIMSLoginButton'), {
  ssr: false,
  loading: () => <></>
})

interface Props {
  themeColor?: {
    trigger: 'light' | 'dark'
    dialog: 'light' | 'dark'
  }
}

const UserProfile: FC<Props> = ({
  themeColor = { trigger: 'dark', dialog: 'light' }
}) => {
  const { localUser } = useAuth()
  const router = useRouter()
  const userName = localUser?.adobeUserDisplayName || 'User'
  const userAvatar = localUser?.adobeUserAvatarUrl || undefined

  return (
    <Provider colorScheme={themeColor.trigger}>
      <DialogTrigger type="popover" placement="bottom" hideArrow offset={10}>
        <ActionButton isQuiet>
          {userAvatar && <Avatar src={userAvatar} alt={userName} size="S" />}
        </ActionButton>
        {close => (
          <Provider colorScheme={themeColor.dialog}>
            <Flex direction="column" minWidth={350}>
              <View padding="size-250">
                <Flex direction="row" alignItems="center" gap="size-250">
                  <img height={60} width={60} src={userAvatar} />
                  <Flex flex={1}>
                    <Heading
                      UNSAFE_style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        margin: 0
                      }}>
                      {userName}
                    </Heading>
                  </Flex>
                </Flex>
              </View>
              <Divider size="S" />
              <View paddingX="size-100">
                <Legal direction="column" />
              </View>
              <View paddingX="size-100" paddingY="size-150">
                {localUser?.isAnonymous ? (
                  <Login
                    variant="secondary"
                    isQuiet
                    width="auto"
                    alignSelf="start"
                    redirectUri={`${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${router?.query?.uuid}`}>
                    Sign In
                  </Login>
                ) : (
                  <Logout
                    variant="secondary"
                    isQuiet
                    width="auto"
                    alignSelf="start">
                    Sign Out
                  </Logout>
                )}
              </View>
            </Flex>
          </Provider>
        )}
      </DialogTrigger>
    </Provider>
  )
}

export default UserProfile
