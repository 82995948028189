import { all, spawn } from 'redux-saga/effects'
import authSaga from '@store/middleware/auth/authSaga'
import sceneSaga from '@store/middleware/document/sceneSaga'
import documentSaga from '@store/middleware/document/documentSaga'
import syncSaga from '@store/middleware/sync/syncSaga'
import projectSaga from '@store/middleware/studio/projectSaga'
import engineSaga from '@store/middleware/studio/engineSaga'
import fireflySaga from '@store/middleware/studio/fireflySaga'
import chimeSaga from './chime/chimeSaga'
import userLibrarySaga from '@store/middleware/userLibrarySaga'
import socketSaga from '@store/middleware/socket/socketSaga'
import monitoringSaga from '@concerns/monitoring/middleware/monitoringSaga'

const RootSaga = function* () {
  yield all([
    spawn(authSaga),
    spawn(projectSaga),
    spawn(engineSaga),
    spawn(documentSaga),
    spawn(sceneSaga),
    spawn(syncSaga),
    fireflySaga(),
    // chimeSaga(),
    spawn(userLibrarySaga),
    spawn(monitoringSaga),
    spawn(socketSaga)
  ])
}

export default RootSaga
