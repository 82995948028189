import {
  CreateAnonymousUserDocument,
  CreateAnonymousUserMutationResult,
  CreateUserDocument,
  CreateUserMutationResult,
  GetAdobeUserDocument,
  GetAdobeUserQueryHookResult,
  UpdateUserDocument,
  UpdateUserMutationResult,
  User
} from '../../graphql/__generated__/schema'
import Router from 'next/router'
import { all, apply, call, put, takeLatest } from 'redux-saga/effects'
import ApolloClient from '@store/graphql/client'
import {
  setLocalUser,
  setImsServiceReady,
  setStatus,
  userLoaded,
  logout,
  setLaunchDarklyContext,
  requestBetaAccess,
  setIsBetaFlowEnabled,
  setIsAuthenticationStateLoaded,
  setIsLocalUserBetaAuthorized,
  setShowPrivateBetaSurveyToast
} from '@store/slices/authSlice'
import AdobeIMS from '@services/auth/IMS'
import { AdobeImsProfile } from 'types/adobeIms'
import Context, { sendDunamisStartEvent } from '../context'
import { retrieveAdobeUserAvatarByAdobeUserId } from '@services/avatar'
import { LocalStorageBooleanValue } from 'types/localStorage'

export const HasViewedPrivateBetaSurveyMessage =
  'has_viewed_private_beta_survey_message'

function showPrivateBetaSurveyToast(user: User) {
  if (!Context.LDClient?.variation('base-tf-ui-private-beta-survey')) {
    return false
  }

  if (!user.insertedAt) {
    return false
  }

  if (
    localStorage.getItem(HasViewedPrivateBetaSurveyMessage) ===
    LocalStorageBooleanValue.TRUE
  ) {
    return false
  }

  const currentDate = Number(new Date())
  const userCreatedDate = Number(new Date(user.insertedAt))
  const oneDay = 24 * 60 * 60 * 1000
  const hasOneDayElapsed = currentDate - userCreatedDate > oneDay
  return hasOneDayElapsed
}

export function* handleSetImsServiceReady() {
  let user: User | null = null
  yield put(setStatus('CHECKING_STATUS'))

  if (AdobeIMS.isSignedInUser()) {
    user = yield call(handleAdobeUser)
  } else {
    if (Context.LDClient?.allFlags()['base-tf-fx-authentication-anonymous']) {
      user = yield call(handleAnonymousUser)
    }
  }

  if (user) {
    yield call(initializeLaunchDarklyWithAuthenticatedUser, user)
    yield put(setLocalUser(user))
    yield put(userLoaded())
    yield put(setStatus('AUTHENTICATED'))

    if (showPrivateBetaSurveyToast(user)) {
      yield put(setShowPrivateBetaSurveyToast(true))
    }
  } else {
    yield call(clearAuthenticationState)
  }

  yield put(setIsAuthenticationStateLoaded(true))
}

export async function handleAdobeUser() {
  const existingAdobeUser = await getAdobeUser()
  if (existingAdobeUser) {
    const userWithLatestProfile = await updateLocalUserProfile(
      existingAdobeUser
    )
    return userWithLatestProfile
  } else {
    const newAdobeUser = await createAdobeUser()
    return newAdobeUser
  }
}

export async function handleAnonymousUser() {
  // TODO: Use a different strategy for preserving anonymous sessions
  // const existingAnonymousUser = await getAnonymousUserByToken()
  const existingAnonymousUser = false
  if (existingAnonymousUser) {
    return existingAnonymousUser
  } else {
    const newAnonymousUser = await createAnonymousUser()
    return newAnonymousUser
  }
}

async function getAdobeUser() {
  const adobeProfile: AdobeImsProfile = await AdobeIMS.getProfile()
  const res = await ApolloClient.query<GetAdobeUserQueryHookResult['data']>({
    fetchPolicy: 'network-only',
    query: GetAdobeUserDocument,
    variables: {
      adobeUserId: adobeProfile.userId
    }
  })
  await sendDunamisStartEvent(
    res?.data?.adobeUser?.uuid!,
    res?.data?.adobeUser?.adobeUserId!
  )
  return res.data?.adobeUser
}

async function createAdobeUser() {
  const adobeProfile: AdobeImsProfile = await AdobeIMS.getProfile()
  const userAvatar = await retrieveAdobeUserAvatarByAdobeUserId(
    adobeProfile.userId
  )

  const res = await ApolloClient.mutate<CreateUserMutationResult['data']>({
    mutation: CreateUserDocument,
    variables: {
      adobeUserId: adobeProfile.userId,
      adobeUserDisplayName: adobeProfile.displayName,
      adobeUserEmail: adobeProfile.email,
      adobeUserAvatarUrl: userAvatar
    }
  })

  return res?.data?.createUser
}

async function createAnonymousUser() {
  const anonymousUser = await ApolloClient.mutate<
    CreateAnonymousUserMutationResult['data']
  >({
    mutation: CreateAnonymousUserDocument
  })

  return anonymousUser?.data?.createAnonymousUser
}

export function* clearAuthenticationState() {
  yield put(setLocalUser(null))
  yield put(setStatus('UNAUTHENTICATED'))
}

export function* handleLogout() {
  yield put(setIsAuthenticationStateLoaded(false))
  yield call(clearAuthenticationState)
  yield AdobeIMS.signOut({
    redirect_uri: `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}`
  })
}

async function updateLocalUserProfile(user: User) {
  const { adobeUserId, adobeUserAvatarUrl } = user
  const updatedAvatar = await retrieveAdobeUserAvatarByAdobeUserId(adobeUserId)

  if (updatedAvatar === adobeUserAvatarUrl) {
    return user
  }

  const updatedUser = await ApolloClient.mutate<
    UpdateUserMutationResult['data']
  >({
    mutation: UpdateUserDocument,
    variables: {
      adobeUserAvatarUrl: updatedAvatar
    }
  })
  return updatedUser.data?.updateUser || user
}

export function* handleBetaAuthorizationRequest() {
  const updatedUser: UpdateUserMutationResult = yield ApolloClient.mutate<
    UpdateUserMutationResult['data']
  >({
    mutation: UpdateUserDocument,
    variables: {
      isBetaAuthorized: true
    }
  })

  const user = updatedUser.data?.updateUser

  if (user) {
    yield put(setLocalUser(user))
    yield call(initializeLaunchDarklyWithAuthenticatedUser, user)
    const redirect = Router.query.redirect_path || '/discover'
    yield apply(window.location, window.location.replace, [redirect as string])
  }
}

export function* updateLaunchDarklyContext(
  user: User,
  isBetaFlowEnabled: boolean
) {
  const userContext = {
    key: user.adobeUserEmail,
    email: user.adobeUserEmail
  }

  const launchDarklyContext = isBetaFlowEnabled
    ? {
        kind: 'multi',
        user: userContext,
        permissions: {
          key: user.adobeUserEmail,
          beta_authorized: user.isBetaAuthorized
        }
      }
    : userContext

  yield Context.LDClient?.identify(launchDarklyContext as any)
  yield put(setLaunchDarklyContext(launchDarklyContext as any))
}

export function* setLaunchDarklyBetaStatus(isBetaFlowEnabled: boolean) {
  yield put(setIsBetaFlowEnabled(isBetaFlowEnabled))

  if (isBetaFlowEnabled) {
    const isLocalUserBetaAuthorized = Context.LDClient?.variation(
      'prerelease-tf-fx-authorized'
    )

    yield put(setIsLocalUserBetaAuthorized(isLocalUserBetaAuthorized))
  }
}

export function* initializeLaunchDarklyWithAuthenticatedUser(user: User) {
  const isBetaFlowEnabled: boolean = yield Context.LDClient?.variation(
    'base-tf-fx-beta-authorization-required'
  )

  yield call(updateLaunchDarklyContext, user, isBetaFlowEnabled)
  yield call(setLaunchDarklyBetaStatus, isBetaFlowEnabled)
}

export default function* authSaga() {
  yield all([
    takeLatest(setImsServiceReady.type, handleSetImsServiceReady),
    takeLatest(requestBetaAccess.type, handleBetaAuthorizationRequest),
    takeLatest(logout.type, handleLogout)
  ])
}
