import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '@store/graphql/__generated__/schema'

export interface AuthenticationState {
  status:
    | 'CHECKING_STATUS'
    | 'ERROR_CHECKING_STATUS'
    | 'AUTHENTICATED'
    | 'UNAUTHENTICATED'
  localUser: User | null
  launchDarklyContext: { key: string; email: string } | null
  onlineUsers: Record<string, {}>
  isBetaFlowEnabled: boolean
  isLocalUserBetaAuthorized: boolean
  isAuthenticationStateLoaded: boolean
  showPrivateBetaSurveyToast: boolean
}

const initialState: AuthenticationState = {
  status: 'CHECKING_STATUS',
  onlineUsers: {},
  localUser: null,
  launchDarklyContext: null,
  isBetaFlowEnabled: false,
  isLocalUserBetaAuthorized: false,
  isAuthenticationStateLoaded: false,
  showPrivateBetaSurveyToast: false
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLocalUser: (state, { payload }: PayloadAction<User | null>) => {
      state.localUser = payload
    },
    setLaunchDarklyContext: (
      state,
      { payload }: PayloadAction<{ key: string; email: string } | null>
    ) => {
      state.launchDarklyContext = payload
    },
    setImsServiceReady: () => {},
    setStatus: (
      state,
      { payload }: PayloadAction<AuthenticationState['status']>
    ) => {
      state.status = payload
    },
    setOnlineUsers: (state, { payload }: PayloadAction<Record<string, {}>>) => {
      state.onlineUsers = payload
    },
    setIsLocalUserBetaAuthorized: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLocalUserBetaAuthorized = payload
    },
    setIsBetaFlowEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isBetaFlowEnabled = payload
    },
    requestBetaAccess: () => {},
    userLoaded: () => {},
    setIsAuthenticationStateLoaded: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAuthenticationStateLoaded = payload
    },
    logout: () => {},
    setShowPrivateBetaSurveyToast: (
      state,
      {
        payload
      }: PayloadAction<AuthenticationState['showPrivateBetaSurveyToast']>
    ) => {
      state.showPrivateBetaSurveyToast = payload
    }
  }
})

export const {
  setImsServiceReady,
  setLocalUser,
  setOnlineUsers,
  setStatus,
  userLoaded,
  setLaunchDarklyContext,
  setIsLocalUserBetaAuthorized,
  setIsBetaFlowEnabled,
  setIsAuthenticationStateLoaded,
  requestBetaAccess,
  logout,
  setShowPrivateBetaSurveyToast
} = auth.actions

export default auth.reducer
