import React, { createContext, useState, useEffect, FC } from 'react'
// @ts-ignore
import SpectrumV2Provider from '@react/react-spectrum/Provider'
import {
  SSRProvider,
  Provider as SpectrumV3Provider,
  defaultTheme
} from '@adobe/react-spectrum'

interface ThemeContextProps {
  isDarkMode: boolean
  handleThemeToggle: () => void
}

interface ThemeProviderProps {
  children: React.ReactNode
  explicitDark?: boolean
}

const ThemeContext = createContext<ThemeContextProps>({
  isDarkMode: false,
  handleThemeToggle: () => {}
})

const DARK_MODE_STORAGE_KEY = 'app_dark_mode'

const ThemeProvider: FC<ThemeProviderProps> = ({ children, explicitDark }) => {
  const [isDarkMode, setIsDarkmode] = useState<boolean>(false)

  const handleThemeToggle = () => {
    const newDarkModeState = !isDarkMode
    setIsDarkmode(newDarkModeState)
    localStorage.setItem(
      DARK_MODE_STORAGE_KEY,
      newDarkModeState ? 'dark' : 'light'
    )
  }

  useEffect(() => {
    const storedDarkMode = localStorage.getItem(DARK_MODE_STORAGE_KEY)
    if (storedDarkMode === 'dark') {
      setIsDarkmode(true)
    } else if (explicitDark === true) {
      setIsDarkmode(true)
    } else {
      setIsDarkmode(false)
    }
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handler = (e: MediaQueryListEvent) => {
      if (explicitDark) {
        setIsDarkmode(e.matches)
      }
    }
    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [explicitDark])

  useEffect(() => {
    localStorage.setItem(DARK_MODE_STORAGE_KEY, isDarkMode ? 'dark' : 'light')
  }, [isDarkMode])

  return (
    <ThemeContext.Provider value={{ isDarkMode, handleThemeToggle }}>
      <SSRProvider>
        <SpectrumV3Provider
          theme={defaultTheme}
          colorScheme={isDarkMode ? 'dark' : 'light'}>
          <SpectrumV2Provider theme={isDarkMode ? 'dark' : 'light'}>
            {children}
          </SpectrumV2Provider>
        </SpectrumV3Provider>
      </SSRProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
