import EventEmitter from "events"
import { eventChannel } from "redux-saga"

export default function createEventChannel(channel: EventEmitter, eventName: string) {
    return eventChannel(emit => {
        channel.on(eventName, emit)
        return () => {
            channel.removeListener(eventName, emit)
        }
    })
}