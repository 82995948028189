import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Asset } from '@store/graphql/__generated__/schema'
import { SceneState } from './sceneSlice'
import { EngineBackground } from '@services/engine/types'

export interface CreateUserLibraryAssetRequest {
  name: string
  type: string
  document: {}
}

export interface ArchiveUserLibraryAssetRequest {
  type: string
  assetUuid: string
}

export type UserLibraryMaterial = Asset & {
  document: {
    materialColor: SceneState['materialColor']
    materialEColorLig: SceneState['materialEColorLig']
    materialEColorTop: SceneState['materialEColorTop']
    materialEColorSha: SceneState['materialEColorSha']
    materialESpecularSize: SceneState['materialESpecularSize']
    materialESpecularIntensity: SceneState['materialESpecularIntensity']
    materialMetalness: SceneState['materialMetalness']
    materialReflective: SceneState['materialReflective']
    materialRoughness: SceneState['materialRoughness']
    materialIColor: SceneState['materialIColor']
    materialIEmissiveColor: SceneState['materialIEmissiveColor']
    materialIStrokeSize: SceneState['materialIStrokeSize']
    materialIHighlightIntensity: SceneState['materialIHighlightIntensity']
    materialIStrokeIntensity: SceneState['materialIStrokeIntensity']
    materialIColorVarIntensity: SceneState['materialIColorVarIntensity']
    materialIScaleX: SceneState['materialIScaleX']
    materialIScaleY: SceneState['materialIScaleY']
    materialIAngle: SceneState['materialIAngle']
    materialKeepStylesInSync: SceneState['materialKeepStylesInSync']
    materialType: SceneState['materialType']
  }
}

export type UserLibraryBackground = Asset & {
  document: {
    backgroundColorA: string
    backgroundColorB: string
    backgroundType: EngineBackground.SOLID
    floorEnabled: boolean
  }
}

export interface UserLibraryState {
  allUserLibraryAssets: Array<Asset> | null
  userLibraryMaterials: Array<UserLibraryMaterial> | null
  userLibraryBackgrounds: Array<UserLibraryBackground> | null
}

const initialState: UserLibraryState = {
  allUserLibraryAssets: null,
  userLibraryMaterials: null,
  userLibraryBackgrounds: null
}

export const userLibrary = createSlice({
  name: 'userLibrary',
  initialState,
  reducers: {
    // Fetch all Assets belonging to current User
    fetchAllUserLibraryAssets: () => {
      return
    },
    setAllUserLibraryAssets: (
      state,
      { payload }: PayloadAction<Array<Asset>>
    ) => {
      state.allUserLibraryAssets = payload
    },
    // Fetch all Assets with type: 'material'  belonging to current User
    fetchUserLibraryMaterials: () => {
      return
    },
    setUserLibraryMaterials: (
      state,
      { payload }: PayloadAction<Array<UserLibraryMaterial>>
    ) => {
      state.userLibraryMaterials = payload
    },
    // Fetch all Assets with type: 'material'  belonging to current User
    fetchUserLibraryBackgrounds: () => {
      return
    },
    setUserLibraryBackgrounds: (
      state,
      { payload }: PayloadAction<Array<UserLibraryBackground>>
    ) => {
      state.userLibraryBackgrounds = payload
    },
    // Create a new asset
    createUserLibraryAsset: (
      _,
      { payload }: PayloadAction<CreateUserLibraryAssetRequest>
    ) => {
      return
    },
    // Archive an asset
    archiveUserLibraryAsset: (
      _,
      { payload }: PayloadAction<ArchiveUserLibraryAssetRequest>
    ) => {
      return
    },
    // Apply material to selected object
    applyUserLibraryMaterialToSelection: (
      _,
      { payload }: PayloadAction<UserLibraryMaterial['document']>
    ) => {
      return
    },
    // Apply material to selected object
    applyUserLibraryBackground: (
      _,
      { payload }: PayloadAction<UserLibraryBackground['document']>
    ) => {
      return
    }
  }
})

export const {
  createUserLibraryAsset,
  fetchAllUserLibraryAssets,
  setAllUserLibraryAssets,
  fetchUserLibraryMaterials,
  setUserLibraryMaterials,
  applyUserLibraryMaterialToSelection,
  archiveUserLibraryAsset,
  applyUserLibraryBackground,
  fetchUserLibraryBackgrounds,
  setUserLibraryBackgrounds
} = userLibrary.actions

export default userLibrary.reducer
