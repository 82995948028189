import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicNumberField = dynamic(
	async () =>
		await import('@swc-react/number-field').then(
			({ NumberField }) => NumberField
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const NumberField: typeof DynamicNumberField = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/number-field/sp-number-field.js')
	}, [])

	return <DynamicNumberField {...props} />
}
