import {
  all,
  call,
  fork,
  take,
  takeEvery,
  debounce,
  takeLatest,
  put,
  putResolve,
  delay,
  select
} from 'redux-saga/effects'
import {
  PropertyPayload,
  SceneState,
  setEngineState,
  addPrimitive,
  setPropertyState,
  duplicatePrimitive,
  undoRedo,
  deletePrimitive,
  selectElementParentChild,
  selectElementSibling,
  copyMaterials,
  transferMaterials,
  pasteMaterials,
  setAutoFocusEnabled,
  exportCapture,
  selectElement,
  recenterCamera,
  cameraReset,
  setCameraOrbitTheta,
  setCameraFromTo,
  setCameraRadius,
  zoomSelection,
  setCameraOrbitPhi,
  setCameraPhi,
  setCameraType,
  setCameraDirection,
  reorderStackElements,
  setCaptureStatus,
  togglePrimitiveVisibility,
  startVideoRecording,
  cancelVideoRecording,
  videoRecordingComplete,
  setPropertiesState
} from '@store/slices/sceneSlice'

import { PayloadAction } from '@reduxjs/toolkit'
import {
  EnginePrimitive,
  EngineData,
  EngineUndoRedo,
  SceneNavigatorEventPayload,
  CameraPosition,
  CameraFromTo,
  Cartesian,
  EngineStackReorderingOpts,
  EngineCaptureImagePayload,
  FrameEventPayload,
  SelectedObjectUI,
  PropertyHasChangedData,
  PropertyListHasChangedData,
  SceneContentData,
  MaterialType,
  PropertySoftBoundsHaveChangedData
} from '@services/engine/types'
import createEventChannel from '@store/middleware/createEventChannel'
import { EventChannel } from 'redux-saga'
import { PayloadType, RootState } from '@store/store'
import Context from '@store/middleware/context'
import { saveAs } from 'file-saver'
import setSceneProperty from '@store/middleware/document/setSceneProperty'
import setSceneElements from './setSceneElements'
import setFrame from './setFrame'
import {
  getPresignedImageUrl,
  getPresignedSVGUrl
} from '@services/storage/fromStorageUrl'
import * as Sentry from '@sentry/nextjs'
import { MediaIO } from '@services/engine/MediaIO'
import { parseISO } from 'date-fns'
import { StatusCodes } from 'http-status-codes'
import setSelectedObjectUI from './setSelectedObjectUI'
import { handlePropertyHasChanged } from './handlePropertyHasChanged'
import { handlePropertySoftBoundsHaveChanged } from './handlePropertySoftBoundsHaveChanged'
import setSceneState from './setSceneState'

function* handleSetEngineState({
  payload
}: PayloadAction<SceneState['engineState']>) {
  if (payload === 'INITIALIZED') {
    yield fork(handlePropertyListHasChangedChannel)
    yield fork(handleSceneContentChannel)
  }

  if (payload === 'INITIALIZED_AND_DOCUMENT_LOADED') {
    // RELY ON NEW DOCUMENT MODEL API INSTEAD
    // yield fork(handleDataChannel)
    yield fork(handleLightingChannel)
    yield fork(handlePropertyHasChangedChannel)
    yield fork(handlePropertySoftBoundsHaveChangedChannel)
    yield fork(handleSceneNavigatorDataChannel)
    yield fork(handleFrameDataChannel)
    yield fork(handleCaptureChannel)
    yield fork(handleIllustrativeTextureChannel)
    yield fork(handleSelectedObjectUI)
  }
}

function* handleAddPrimitive({ payload }: PayloadAction<EnginePrimitive>) {
  try {
    yield Context.Engine?.addPrimitive(payload)
  } catch (e) {
    console.error(e)
  }
}

function* handleTogglePrimitiveVisibility({
  payload
}: PayloadAction<PayloadType<typeof togglePrimitiveVisibility>>) {
  try {
    yield Context.Engine?.togglePrimitiveVisibility(payload.uuid)
  } catch (e) {
    console.error(e)
  }
}

function* handleSetPropertyState({ payload }: PayloadAction<PropertyPayload>) {
  try {
    yield fork(setSceneProperty, payload)
  } catch (e) {
    console.error(e)
  }
}

function* handleDuplicatePrimitiveState({ payload }: PayloadAction<void>) {
  yield Context.Engine?.duplicatePrimitive()
}

function* handleDeletePrimitiveState({ payload }: PayloadAction<void>) {
  yield Context.Engine?.deletePrimitive()
}

function* handleUndoRedoState({ payload }: PayloadAction<EngineUndoRedo>) {
  yield Context.Engine?.handleUndoRedo(payload)
}

function* handleSelectElementParentChild({
  payload
}: PayloadAction<PayloadType<typeof selectElementParentChild>>) {
  yield Context.Engine?.selectParentChild(payload)
}

function* handleSelectElementSibling({
  payload
}: PayloadAction<PayloadType<typeof selectElementSibling>>) {
  yield Context.Engine?.selectSibling(payload)
}

function* handleDataChannel() {
  const dataChannel: EventChannel<EngineData> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'data'
  )
  while (true) {
    try {
      const dataEvent: EngineData = yield take(dataChannel)
      yield fork(handleEngineData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleLightingChannel() {
  if (
    !Context.Engine?.EngineLightingChannel ||
    !Context.Engine.engineLightingChannelKey
  )
    return

  const dataChannel: EventChannel<EngineData> = yield call(
    createEventChannel,
    Context.Engine.EngineLightingChannel,
    Context.Engine.engineLightingChannelKey
  )

  while (true) {
    try {
      const dataEvent = yield take(dataChannel)

      const color = dataEvent.occlusion.color
      const lightOcclusionColor = Context.Engine.rGBToHex(
        color[0],
        color[1],
        color[2]
      )

      yield put(
        setPropertiesState({
          lightAngle1: dataEvent.lights[0].position,
          lightAngle2: dataEvent.lights[0].altitude,
          lightOcclusionDistance: dataEvent.occlusion.distance,
          lightOcclusionColor
        })
      )
    } catch (err) {
      console.log(err)
    }
  }
}

function* handleSelectedObjectUI() {
  const dataChannel: EventChannel<EngineData> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'selected-object-ui'
  )
  while (true) {
    try {
      const dataEvent: SelectedObjectUI = yield take(dataChannel)
      yield fork(handleInSceneUIData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleSceneContentChannel() {
  const dataChannel: EventChannel<SceneContentData> = yield call(
    createEventChannel,
    Context.Engine?.EngineSceneContentChannel,
    Context.Engine?.engineSceneContentChannelKey
  )

  while (true) {
    try {
      const data: SceneContentData = yield take(dataChannel)
      yield fork(handleSceneContentData, data)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handlePropertyListHasChangedChannel() {
  const dataChannel: EventChannel<PropertyListHasChangedData> = yield call(
    createEventChannel,
    Context.Engine?.EnginePropertyListHasChangedChannel,
    Context.Engine?.enginePropertyListHasChangedChannelKey
  )

  while (true) {
    try {
      const dataEvent: PropertyListHasChangedData = yield take(dataChannel)
      yield fork(handlePropertyListHasChangedData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handlePropertyHasChangedChannel() {
  const dataChannel: EventChannel<PropertyHasChangedData> = yield call(
    createEventChannel,
    Context.Engine?.EnginePropertyHasChangedChannel,
    Context.Engine?.enginePropertyHasChangedChannelKey
  )
  while (true) {
    try {
      const dataEvent: PropertyHasChangedData = yield take(dataChannel)

      yield fork(handlePropertyHasChangedData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handlePropertySoftBoundsHaveChangedChannel() {
  const dataChannel: EventChannel<PropertySoftBoundsHaveChangedData> =
    yield call(
      createEventChannel,
      Context.Engine?.EnginePropertySoftBoundsHaveChangedChannel,
      Context.Engine?.enginePropertySoftBoundsHaveChangedChannelKey
    )
  while (true) {
    try {
      const dataEvent: PropertySoftBoundsHaveChangedData = yield take(
        dataChannel
      )

      yield fork(handlePropertySoftBoundsHaveChangedData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleInSceneUIData(dataEvent: SelectedObjectUI) {
  yield fork(setSelectedObjectUI, dataEvent)
}

function* handleCaptureChannel() {
  const dataChannel: EventChannel<EngineCaptureImagePayload> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'capture_image_data'
  )
  while (true) {
    try {
      const { format, objectURL, blobArray }: EngineCaptureImagePayload =
        yield take(dataChannel)

      const {
        project: { projectUuid, name, isFeatured, ownerUserUuid },
        scene: {
          captureStatus: { workflow }
        }
      }: RootState = yield select((state: RootState) => state)

      const { localUser }: RootState['auth'] = yield select(
        (state: RootState) => state.auth
      )

      if (workflow !== 'send-to-adobe-illustrator') {
        yield put(
          setCaptureStatus({
            format,
            status: 'completed',
            workflow: workflow
          })
        )
      }

      if (format === 'screenshot') {
        if (!projectUuid) {
          Sentry.captureException(
            new Error('screenshot upload: Project does not exist!')
          )
        }

        if (projectUuid === undefined) return
        // double check to see if user is the owner and is not featured and blobArray is not undefined
        const isOwner = ownerUserUuid === localUser?.uuid
        if (!isOwner) {
          Sentry.captureException(
            new Error('screenshot upload: User is not owner!')
          )
        }

        const canUpload = blobArray && projectUuid && !isFeatured && isOwner
        if (canUpload) {
          if (blobArray.length > 0) {
            yield fork(handleScreenshotCaptureThumbnailUpdate, {
              projectUuid,
              objectURL
            })
          }
        }
      } else if (workflow === 'download') {
        saveAs(objectURL, `${name}.${format}`)
      } else if (
        workflow === 'send-to-adobe-illustrator' &&
        projectUuid &&
        name
      ) {
        yield openAdobeIllustrator(projectUuid, name, objectURL)
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
  }
}

function* openAdobeIllustrator(
  projectUuid: string,
  projectName: string,
  objectURL: string
) {
  yield call(saveSVG, projectUuid, projectName, objectURL)

  const downloadUrl = `${process.env.NEXT_PUBLIC_SERVICE_CORE_STORAGE}/storage/projects/${projectUuid}/svg/${projectName}.svg`

  yield put(
    setCaptureStatus({
      format: 'svg',
      status: 'completed',
      workflow: 'send-to-adobe-illustrator'
    })
  )

  window.open(
    `https://creativecloud.adobe.com/campaign/illustrator?workflow=open-doc&doc-type=download&doc-path=${encodeURIComponent(
      downloadUrl
    )}`,
    '_blank'
  )
}

async function saveSVG(
  projectUuid: string,
  projectName: string,
  objectURL: string
) {
  try {
    const presignedImageUrlResponse = await getPresignedSVGUrl(
      projectUuid,
      projectName
    )
    if (!presignedImageUrlResponse) return

    const presignedImageUrl = await presignedImageUrlResponse.text()

    const blob: Blob = await fetch(objectURL).then(res => res.blob())

    await fetch(presignedImageUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/svg+xml'
      },
      body: blob
    })

    URL.revokeObjectURL(objectURL)
  } catch (e) {
    console.error(e)
  }
}

function* handleIllustrativeTextureChannel() {
  const dataChannel: EventChannel<boolean> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'illustrativeTexturesLoaded'
  )

  while (true) {
    const illustrativeTexturesLoaded: boolean = yield take(dataChannel)

    yield put(
      setPropertyState({
        key: 'illustrativeTexturesLoaded',
        value: illustrativeTexturesLoaded
      })
    )
  }
}

function extractExpirationFromPresignedUrl(presignedUrl: string): number {
  const queryParams = new URL(presignedUrl).searchParams
  const expiration: number = Number(queryParams.get('X-Amz-Expires'))
  return expiration * 1000 // Multiply by 1000 because the value of the query param is in seconds, but we want it in milliseconds
}

function extractCreationTimeFromPresignedUrl(presignedUrl: string): number {
  const queryParams = new URL(presignedUrl).searchParams
  const creationTime: string | null = queryParams.get('X-Amz-Date')

  if (!creationTime) {
    return new Date().getTime()
  }

  return new Date(parseISO(creationTime)).getTime()
}

function isPresignedUrlExpired(presignedUrl: string): boolean {
  const expiration = extractExpirationFromPresignedUrl(presignedUrl)
  const creationTime = extractCreationTimeFromPresignedUrl(presignedUrl)
  return creationTime + expiration < new Date().getTime()
}

function* handleScreenshotCaptureThumbnailUpdate({
  projectUuid,
  objectURL
}: any) {
  try {
    let presignedImageUrlData: RootState['scene']['presignedImageUrlData'] =
      yield select((state: RootState) => state.scene.presignedImageUrlData)

    const ownerUserUuid: RootState['project']['ownerUserUuid'] = yield select(
      (state: RootState) => state.project.ownerUserUuid
    )

    const localUser: RootState['auth']['localUser'] = yield select(
      (state: RootState) => state.auth.localUser
    )

    const isOwner = ownerUserUuid === localUser?.uuid
    if (!isOwner) return

    // Presigned url is expired, non-existent, or the current stored one
    // is associated with a different project. Need to fetch a new one
    if (
      projectUuid !== presignedImageUrlData.projectUuid ||
      presignedImageUrlData.url === '' ||
      isPresignedUrlExpired(presignedImageUrlData.url)
    ) {
      const presignedImageUrlResponse: Response | undefined = yield call(
        getPresignedImageUrl,
        projectUuid
      )

      if (!presignedImageUrlResponse) return

      const url: string = yield presignedImageUrlResponse.text()

      presignedImageUrlData = {
        projectUuid,
        url
      }

      yield put(
        setPropertyState({
          key: 'presignedImageUrlData',
          value: { projectUuid, url }
        })
      )
    }

    const blob: Blob = yield fetch(objectURL).then(res => res.blob())

    const { status } = yield fetch(presignedImageUrlData.url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/jpg'
      },
      body: blob
    })

    // If the PUT is not successful, assume it's due to an invalid presigned url
    // and invalidate the current presigned url so that a fresh one is fetched
    // the next time a thumbnail uploaded is attempted
    if (status !== StatusCodes.OK) {
      yield put(
        setPropertyState({
          key: 'presignedImageUrlData',
          value: { projectUuid, url: '' }
        })
      )
    }

    URL.revokeObjectURL(objectURL)
  } catch (e) {
    console.error(e)
  }
}

function* handleSceneNavigatorDataChannel() {
  const dataChannel: EventChannel<SceneNavigatorEventPayload> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'scene-navigator-data'
  )
  while (true) {
    try {
      const dataEvent: SceneNavigatorEventPayload = yield take(dataChannel)
      yield fork(handleSceneNavigatorData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleFrameDataChannel() {
  const dataChannel: EventChannel<FrameEventPayload> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'frame-data'
  )
  while (true) {
    try {
      const dataEvent: FrameEventPayload = yield take(dataChannel)
      yield fork(handleFrameData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleCameraPositionDataChannel() {
  const dataChannel: EventChannel<CameraPosition> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'camera-position-data'
  )
  while (true) {
    try {
      const dataEvent: CameraPosition = yield take(dataChannel)
      yield fork(handleCameraPositionData, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleEngineData(dataEvent: EngineData) {
  yield fork(setSceneState, dataEvent)
}

function* handleSceneContentData(data: SceneContentData) {
  const { primitiveType, selectedSceneNode, styleMode } = data

  yield put(
    setPropertiesState({
      primitiveType,
      selectedSceneNode,
      mode: styleMode,
      materialType: styleMode as unknown as MaterialType
    })
  )
}

function* handlePropertyListHasChangedData(
  dataEvent: PropertyListHasChangedData
) {
  const panelId = ''
  for (const g of dataEvent.UI.Groups) {
    for (const p of g.Properties) {
      const path = p.path
      const type = p.type
      let value = p.value.value

      if (
        type === 'ivec2' ||
        type === 'vec2d' ||
        type === 'vec3d' ||
        type === 'ivec3'
      ) {
        value = p.value
      } else if (type === 'EnumBase') {
        value = p.value.enumIndex
      }

      yield call(handlePropertyHasChanged, { path, panelId, value })
    }
  }
}

function* handlePropertyHasChangedData(dataEvent: PropertyHasChangedData) {
  yield fork(handlePropertyHasChanged, dataEvent)
}

function* handlePropertySoftBoundsHaveChangedData(
  dataEvent: PropertySoftBoundsHaveChangedData
) {
  yield fork(handlePropertySoftBoundsHaveChanged, dataEvent)
}

function* handleSceneNavigatorData(dataEvent: SceneNavigatorEventPayload) {
  yield fork(setSceneElements, dataEvent)
}

function* handleFrameData(dataEvent: FrameEventPayload) {
  yield fork(setFrame, dataEvent)
}

function* handleCopyMaterials() {
  yield Context.Engine?.copyMaterials()
}

function* handleTransferMaterials() {
  yield Context.Engine?.transferMaterials()
}

function* handlePasteMaterials() {
  yield Context.Engine?.pasteMaterials()
}

function* handlePixelOutlineChange({ payload }: PayloadAction<boolean>) {
  const changedValue = payload === true ? 1 : 0
  yield Context.Engine?.setStyleParamExplicit(3, changedValue, 1)
}
function* handleIllustrativeOutlineChange({ payload }: PayloadAction<boolean>) {
  const changedValue = payload === true ? 1 : 0
  yield Context.Engine?.setStyleParamExplicit(7, changedValue, 1)
}

function* handleEnableAutoFocus({ payload }: PayloadAction<boolean>) {
  yield Context.Engine?.setAutoFocusEnabled(payload)
}

function* handleExportCapture({
  payload
}: PayloadAction<PayloadType<typeof exportCapture>>) {
  const { format, workflow } = payload
  yield putResolve(setCaptureStatus({ status: 'exporting', format, workflow }))

  // delay is required to first show exporting dialog because Context.Engine?.exportCapture blocks state changes while exporting
  if (format !== 'screenshot') yield delay(1000)

  yield Context.Engine?.exportCapture(payload)
}

function* handleSelectElement({
  payload: { uuid, multiSelect }
}: PayloadAction<PayloadType<typeof selectElement>>) {
  yield Context.Engine?.selectElementByUUID(uuid, multiSelect)
}

function* handleRecenterCamera() {
  yield Context.Engine?.recenterCamera()
}

function* handleCameraReset() {
  yield Context.Engine?.cameraReset()
}

function* handleSetCameraType({ payload }: PayloadAction<number>) {
  yield Context.Engine?.setCameraType(payload)
}

function* handleSetCameraFromTo({ payload }: PayloadAction<CameraFromTo>) {
  yield Context.Engine?.setCameraFromTo(payload)
}

function* handleSetCameraRadius({ payload }: PayloadAction<number>) {
  yield Context.Engine?.setCameraRadius(payload)
}

function* handleSetCameraPhi({ payload }: PayloadAction<number>) {
  yield Context.Engine?.setCameraPhi(payload)
}

function* handleZoomSelection({ payload }: PayloadAction<number>) {
  yield Context.Engine?.zoomSelection(payload)
}

function* handleSetCameraOrbitPhi({ payload }: PayloadAction<number>) {
  yield Context.Engine?.setCameraOrbitPhi(payload)
}

function* handleSetCameraOrbitTheta({ payload }: PayloadAction<number>) {
  yield Context.Engine?.setCameraOrbitTheta(payload)
}

function* handleSetCameraDirection({ payload }: PayloadAction<Cartesian>) {
  yield Context.Engine?.setCameraDirection(payload)
}

function* handleReorderStackElements({
  payload: { srcIndices, destIndex, mode }
}: PayloadAction<EngineStackReorderingOpts>) {
  yield Context.Engine?.reorderStackElements({ srcIndices, destIndex, mode })
}

function* handleStartVideoRecording() {
  const { startVideoEncoding } = MediaIO
  yield put(setPropertyState({ key: 'showVideoExportDialog', value: true }))

  // Gives React time to render VideoExportDialog before starting the actual encoding.
  // If there is no delay, then the encoding can start before React renders the component
  // since React state updates are asynchronous. This causes there to be a delay in
  // VideoExportDialog rendering due to startVideoEncoding slowing down the UI.
  yield delay(100)

  const { videoEncodingParameters, videoAnimationParameters } = yield select(
    (state): RootState => state.scene
  )

  yield call(
    startVideoEncoding,
    videoEncodingParameters,
    videoAnimationParameters,
    true
  )
}

function* handleCancelVideoRecording() {
  yield call(resetVideoRecordingState)

  const { cancelCurrentVideoEncodingOrPreview } = MediaIO
  yield call(cancelCurrentVideoEncodingOrPreview)
}

function* handleVideoRecordingComplete() {
  yield call(resetVideoRecordingState)
}

function* resetVideoRecordingState() {
  yield put(
    setPropertyState({
      key: 'canvasAnimationStartedForRecording',
      value: false
    })
  )

  yield put(setPropertyState({ key: 'showVideoExportDialog', value: false }))

  yield call(setVideoEncodingParametersToNull)
  yield call(deleteVideoParameters)
}

// This is a must - it calls the destructor on the c++ side
function* deleteVideoParameters() {
  const { videoEncodingParameters, videoAnimationParameters } = yield select(
    (state): RootState => state.scene
  )

  if (videoEncodingParameters) videoEncodingParameters.delete()
  if (videoAnimationParameters) videoAnimationParameters.delete()
}

function* setVideoEncodingParametersToNull() {
  yield put(setPropertyState({ key: 'videoEncodingParameters', value: null }))
  yield put(setPropertyState({ key: 'videoAnimationParameters', value: null }))
}

export default function* sceneSaga() {
  yield all([
    takeEvery(setEngineState.type, handleSetEngineState),
    takeEvery(setPropertyState.type, handleSetPropertyState),
    takeEvery(duplicatePrimitive.type, handleDuplicatePrimitiveState),
    takeEvery(deletePrimitive.type, handleDeletePrimitiveState),
    takeEvery(undoRedo.type, handleUndoRedoState),
    takeEvery(addPrimitive.type, handleAddPrimitive),
    takeEvery(togglePrimitiveVisibility.type, handleTogglePrimitiveVisibility),
    takeEvery(selectElementParentChild.type, handleSelectElementParentChild),
    takeEvery(selectElementSibling.type, handleSelectElementSibling),
    takeEvery(copyMaterials.type, handleCopyMaterials),
    takeEvery(transferMaterials.type, handleTransferMaterials),
    takeEvery(pasteMaterials.type, handlePasteMaterials),
    takeLatest(setAutoFocusEnabled.type, handleEnableAutoFocus),
    takeLatest(exportCapture.type, handleExportCapture),
    takeLatest(selectElement.type, handleSelectElement),
    takeLatest(recenterCamera.type, handleRecenterCamera),
    takeLatest(cameraReset.type, handleCameraReset),
    takeLatest(setCameraFromTo.type, handleSetCameraFromTo),
    takeLatest(setCameraRadius.type, handleSetCameraRadius),
    takeLatest(setCameraPhi.type, handleSetCameraPhi),
    takeLatest(zoomSelection.type, handleZoomSelection),
    takeLatest(setCameraType.type, handleSetCameraType),
    takeLatest(setCameraOrbitPhi.type, handleSetCameraOrbitPhi),
    takeLatest(setCameraOrbitTheta.type, handleSetCameraOrbitTheta),
    takeLatest(setCameraDirection.type, handleSetCameraDirection),
    takeLatest(reorderStackElements.type, handleReorderStackElements),
    takeEvery(startVideoRecording.type, handleStartVideoRecording),
    takeEvery(cancelVideoRecording.type, handleCancelVideoRecording),
    takeEvery(videoRecordingComplete.type, handleVideoRecordingComplete)
  ])
}
