import React, { useEffect, useState } from 'react'
import Close from '@spectrum-icons/workflow/Close'
import { useSync } from '@hooks/useSync'
import cn from 'classnames'
import styles from '@styles/components/UserFollowingIndicator.module.scss'
import { useAuth } from '@hooks/useAuth'

const rgbColor = (r: number, g: number, b: number) => `rgb(${r}, ${g}, ${b})`

const Indicator = ({
  color,
  onClick,
  isHoveringText,
  isNotHoveringText
}: {
  color: string
  onClick: () => void
  isHoveringText: string
  isNotHoveringText: string
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <div
      className={cn(styles['container'], {
        [styles['container--visible']]: isVisible,
        [styles['container--hidden']]: !isVisible
      })}
      onClick={onClick}
      onContextMenu={onClick}
      style={{
        // TODO read color from state
        borderColor: color
      }}>
      <button
        className={styles['user-following']}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onClick}
        style={{
          background: color
        }}>
        {isHovering ? isHoveringText : isNotHoveringText}
        {isHovering && <Close size="S" />}
      </button>
    </div>
  )
}

const UserFollowingIndicator = () => {
  const {
    localUserFollowingRemoteUser,
    setLocalUserFollowingRemoteUser,
    remoteUsersFollowingLocalUser,
    remoteUsers,
    endRemoteUsersFollowSession
  } = useSync()
  const { localUser } = useAuth()

  if (localUserFollowingRemoteUser) {
    return (
      <Indicator
        color={
          localUserFollowingRemoteUser?.preferences.color &&
          rgbColor(...localUserFollowingRemoteUser.preferences.color)
        }
        onClick={() => setLocalUserFollowingRemoteUser(null)}
        isHoveringText={`Stop Following ${localUserFollowingRemoteUser?.adobe_user_display_name}`}
        isNotHoveringText={`Following ${localUserFollowingRemoteUser?.adobe_user_display_name}`}
      />
    )
  }

  if (remoteUsersFollowingLocalUser.length) {
    const currentLocalUser = remoteUsers.find(
      ({ user }) => user.uuid === localUser?.uuid
    )
    const buttonText = `${remoteUsersFollowingLocalUser.length} Followers`
    if (!currentLocalUser) return null
    return (
      <Indicator
        color={
          currentLocalUser?.user.preferences.color &&
          rgbColor(...currentLocalUser.user.preferences.color)
        }
        onClick={() => endRemoteUsersFollowSession()}
        isHoveringText={buttonText}
        isNotHoveringText={buttonText}
      />
    )
  }
  return null
}

export default UserFollowingIndicator
