import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTab = dynamic(
  async () => await import('@swc-react/tabs').then(({ Tab }) => Tab),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Tab: typeof DynamicTab = props => {
  useEffect(() => {
    import('@spectrum-web-components/tabs/sp-tab.js')
  }, [])

  return <DynamicTab {...props} />
}
