import { User } from '@store/graphql/__generated__/schema'
import AdobeIMS from './IMS'
import Router, { NextRouter } from 'next/router'
import { AuthenticationState } from '@store/slices/authSlice'

export const protectedPaths: Array<RegExp> = [
  /\/studio\//,
  /\/discover/,
  /\/my-projects/
]

export const isCurrentPathProtected = (): boolean => {
  return protectedPaths.some(p => window.location.pathname.match(p))
}

export const shouldBlockPagehWhileCheckingLogin = (
  auth: AuthenticationState
) => {
  return isCurrentPathProtected() && !auth.isAuthenticationStateLoaded
}

export const shouldCurrentPathRequireLogin = (
  auth: AuthenticationState
): boolean => {
  return (
    isCurrentPathProtected() &&
    auth.isAuthenticationStateLoaded &&
    auth.status === 'UNAUTHENTICATED' &&
    !window.location.href.includes('#old_hash=&from_ims=true')
  )
}

export const shouldBlockPageWhileCheckingBetaAuthorization = (
  auth: AuthenticationState
) => {
  return (
    isCurrentPathProtected() &&
    auth.isBetaFlowEnabled &&
    !auth.isAuthenticationStateLoaded
  )
}

export const shouldCurrentPathRequireBetaAuthorization = (
  auth: AuthenticationState
): boolean => {
  return (
    isCurrentPathProtected() &&
    auth.isBetaFlowEnabled &&
    auth.isAuthenticationStateLoaded &&
    auth.status === 'AUTHENTICATED' &&
    !auth.isLocalUserBetaAuthorized
  )
}

export const redirectToBetaAuthorizationStep = (
  originalPathname: string
): void => {
  Router.push({
    pathname: '/',
    query: {
      require_beta_authorization: true,
      redirect_path: originalPathname
    }
  })
}

export const loginAndPreserveUrlParams = (
  routerQueryObject: NextRouter['query']
) => {
  if (isQueryEmpty(routerQueryObject)) {
    return AdobeIMS.signIn({
      redirect_uri: window.location.href
    })
  } else {
    const redirectUrlAfterLogin = new URL(
      removeTrailingHashFromUrl(window.location.href)
    )

    for (const property in routerQueryObject) {
      redirectUrlAfterLogin.searchParams.append(
        property,
        routerQueryObject[property] as string
      )
    }
    return AdobeIMS.signIn({
      redirect_uri: redirectUrlAfterLogin
    })
  }
}

export const redirectFromSplashPage = (
  routerQueryObject: NextRouter['query']
) => {
  return Router.push((routerQueryObject.redirect_path as string) || '/discover')
}

export const showBetaAgreementModalIfUrlParamsRequireAuthorization = (
  localUser: User | null,
  isLocalUserBetaAuthorized: boolean,
  showModal: (v: React.SetStateAction<boolean>) => void
) => {
  if (
    localUser &&
    !isLocalUserBetaAuthorized &&
    Router.query.require_beta_authorization === 'true'
  ) {
    showModal(true)
  }
}

export const removeTrailingHashFromUrl = (url: string): string => {
  return url[url.length - 1] === '#' ? url.slice(0, -1) : url
}

export const isQueryEmpty = (
  routerQueryObject: NextRouter['query']
): boolean => {
  return JSON.stringify(routerQueryObject) === '{}'
}
