import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { User } from '@store/graphql/__generated__/schema'

export interface ChimeState {
  meetingId: null | MeetingId
  localUserAttendeeId: null | AttendeeId
}

const initialState: ChimeState = {
  meetingId: null,
  localUserAttendeeId: null
}

type MeetingId = string
type AttendeeId = string

export const chime = createSlice({
  name: 'chime',
  initialState,
  reducers: {
    sendChimeMeetingStartMessage: (
      _,
      {
        payload
      }: PayloadAction<{
        meeting_id: MeetingId
        user: User
      }>
    ) => {},
    sendChimeMeetingJoinMessage: (_, { payload }: PayloadAction<User>) => {},
    sendChimeMeetingLeaveMessage: (_, { payload }: PayloadAction<User>) => {},
    setChimeMeetingId: (
      state,
      { payload }: PayloadAction<MeetingId | null>
    ) => {
      state.meetingId = payload
    },
    setLocalUserAttendeeId: (
      state,
      { payload }: PayloadAction<AttendeeId | null>
    ) => {
      state.localUserAttendeeId = payload
    }
  }
})

export const {
  sendChimeMeetingStartMessage,
  setChimeMeetingId,
  setLocalUserAttendeeId,
  sendChimeMeetingJoinMessage,
  sendChimeMeetingLeaveMessage
} = chime.actions

export default chime.reducer
