import * as Sentry from '@sentry/nextjs'

export function isMacOSX(): boolean {
  return navigator.userAgent.indexOf('Mac OS X') != -1
}

export function getCmdModifier(): string {
  return isMacOSX() ? 'Cmd' : 'Ctrl'
}

export function getAltModifier(): string {
  return isMacOSX() ? 'Option' : 'Alt'
}

export function parseValidEngineJson(data: string) {
  const json = JSON.parse(data)
  if (!json) throw new Error()
  if (typeof json !== 'object') throw new Error()
  if (Array.isArray(json)) throw new Error()
  if (Object.keys(json).length === 0) throw new Error()
  return json
}

export function traceInvalidJsonError(
  filename: string,
  fn: string,
  data: string,
  customErr?: string
): void {
  const err = new Error(
    customErr ||
      'Caught invalid JSON before emitting engine data (changes not emitted)'
  )

  const trace = {
    filename,
    fn,
    invalid_data: data
  }

  // Send to Sentry
  Sentry.captureException(JSON.stringify(trace))
}

export function degToRad(angle: number): number {
  return (angle * Math.PI) / 180
}

export function radToDeg(angle: number): number {
  return (angle * 180) / Math.PI
}
