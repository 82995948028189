import React, { FC } from 'react'
import { Button, Icon } from 'ui'
import DiscordLogoIcon from '/public/discord-mark-blue.svg'

interface props {
  disabled?: boolean
}

export const DiscordLink = 'https://discord.gg/QqkgvBG7hR'

const JoinDiscordButton: FC<props> = ({ disabled }) => {
  const iconStyle = {
    fill: disabled ? 'grey' : '#5865F2'
  }

  return (
    <Button
      variant="secondary"
      treatment="fill"
      href={DiscordLink}
      target="_blank"
      disabled={disabled}
      style={{
        whiteSpace: 'nowrap'
      }}>
      <Icon slot="icon">
        <DiscordLogoIcon style={iconStyle} />
      </Icon>
      Join Discord
    </Button>
  )
}

export default JoinDiscordButton
