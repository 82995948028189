import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTextfield = dynamic(
	async () =>
		await import('@swc-react/textfield').then(
			({ Textfield }) => Textfield
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Textfield: typeof DynamicTextfield = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/textfield/sp-textfield.js')
	}, [])

	return <DynamicTextfield {...props} />
}