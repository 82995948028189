import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicActionGroup = dynamic(
	async () =>
		await import('@swc-react/action-group').then(
			({ ActionGroup }) => ActionGroup
		),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const ActionGroup: typeof DynamicActionGroup = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/action-group/sp-action-group.js')
	}, [])

	return <DynamicActionGroup {...props} />
}
