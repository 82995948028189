import React, { FC, useState } from 'react'
import cn from 'classnames'
import styles from '@styles/components/EmojiPicker.module.scss'
import EmojiReaction from '@components/emojiReaction/EmojiReaction'
import { useSync } from '@hooks/useSync'

interface EmojiPickerProps {
  onSelected?: () => void
}

const EmojiPicker: FC<EmojiPickerProps> = ({ onSelected }) => {
  const { sendLocalUserEmojiMessageValue, sendLocalUserSubmitMessageValue } =
    useSync()
  const [selectedEmoji, setSelectedEmoji] = useState<string>('')
  const [isAnimating, setIsAnimating] = useState<boolean>(false)

  const handleEmojiClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isAnimating) return
    setSelectedEmoji(e.currentTarget.innerText)
    setIsAnimating(true)
    sendLocalUserEmojiMessageValue({
      emoji_message: e.currentTarget.innerText
    })
    sendLocalUserSubmitMessageValue({
      submit_message: e.currentTarget.innerText
    })

    setTimeout(() => {
      setIsAnimating(false)
      onSelected?.()
    }, 3000)
  }

  return (
    <>
      <div className={cn(styles['emoji-picker'])}>
        <div className="flex flex-row gap-xs justify-center">
          <span onClick={handleEmojiClick}>😍</span>
          <span onClick={handleEmojiClick}>😱</span>
          <span onClick={handleEmojiClick}>🤯</span>
        </div>
      </div>
      {isAnimating && <EmojiReaction emoji={selectedEmoji} />}
    </>
  )
}

export default EmojiPicker
