import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export enum IncompatibilityError {
  ok,

  unsupportedBrowser,
  webGlUnsupported,
  incorrectAngleBackend
}

export const useBrowserIncompatibilityChecker = (): IncompatibilityError => {
  const router = useRouter()
  const flags = useFlags()

  const checkCompatibility = function (): IncompatibilityError {
    if (typeof navigator === 'undefined')
      return IncompatibilityError.unsupportedBrowser

    const isMac =
      navigator.platform.toUpperCase().includes('MAC') ||
      navigator.userAgent.includes('Mac OS')

    if (!isMac) return IncompatibilityError.ok

    const isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      // Mac running Safari: run everywhere
      return IncompatibilityError.ok
    }

    // Mac not running safari:

    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl2')
    if (!gl) {
      canvas.remove()
      return IncompatibilityError.webGlUnsupported
    }

    // If it's an ANGLE backend, it should be metal based
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
    if (!debugInfo) {
      canvas.remove()
      return IncompatibilityError.unsupportedBrowser
    }

    const debugRenderer = gl
      .getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
      .toLowerCase()
    console.log('debugRenderer=' + debugRenderer)
    canvas.remove()

    const isAngle = debugRenderer.includes('angle')
    if (!isAngle) {
      return IncompatibilityError.unsupportedBrowser
    }

    const isMetal = debugRenderer.includes('metal')
    if (!isMetal) {
      return IncompatibilityError.incorrectAngleBackend
    }

    return IncompatibilityError.ok
  }

  const err = checkCompatibility()

  useEffect(() => {
    if (typeof window !== 'undefined' && err != IncompatibilityError.ok) {
      router.replace('/compatibility')
    }
  }, [flags])

  return err
}
