import UAParser from 'ua-parser-js'

const uaParser = new UAParser()

export function getDeviceID() {
  let id = localStorage.getItem('did')

  if (!id) {
    id = crypto.randomUUID()
    localStorage.setItem('did', id)
  }

  return id
}

export function getOSName(): string {
  return uaParser.getOS().name || '(unknown OS name)'
}

export function getOSVersion(): string {
  return uaParser.getOS().version || '(unknown OS version)'
}

export function getDeviceName(): string {
  return `${uaParser.getDevice().vendor || '(unknown vendor)'} ${
    uaParser.getDevice().model || '(unknown model)'
  }`
}
