import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicRadioGroup = dynamic(
  async () =>
    await import('@swc-react/radio').then(({ RadioGroup }) => RadioGroup),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const RadioGroup: typeof DynamicRadioGroup = props => {
  useEffect(() => {
    import('@spectrum-web-components/radio/sp-radio-group.js')
  }, [])

  return <DynamicRadioGroup {...props} />
}
