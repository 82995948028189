import { FC, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import type { AppProps } from 'next/app'
import { Provider as ReduxProvider } from 'react-redux'
import { RootStore } from '@store/store'
import client from '@store/graphql/client'
import { ThemeProvider } from '@contexts/ThemeContext'
import DefaultLayout from '@components/layouts/DefaultLayout'
import '@styles/reset.scss'
import '@styles/utility.scss'
import '@styles/studio.scss'
import '@styles/global-variables.scss'
import '@styles/font-face.scss'
import AdobeIMS from '@services/auth/IMS'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk'
import { useBrowserIncompatibilityChecker } from '@hooks/useBrowserIncompatibilityChecker'
import { initializeLDClient } from '@store/middleware/context'
import dynamic from 'next/dynamic'
import RequireBetaAuthorization from '@services/auth/RequireBetaAuthorization'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Script from 'next/script'
import AdobeAnalyticsWrapper from '@concerns/monitoring/service/adobeAnalyticsWrapper'

const StudioLayout = dynamic(() => import('@components/layouts/StudioLayout'), {
  ssr: false
})

dayjs.extend(relativeTime)

type LayoutProps = {
  children: React.ReactNode
}

const layouts: { [key: string]: FC<LayoutProps> } = {
  DefaultLayout,
  StudioLayout,
  none: ({ children }) => <>{children}</>
}

const RequireAuth = dynamic(() => import('@services/auth/RequireAuth'), {
  ssr: false
})

const AppRoot = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const Layout = layouts[Component.layout] || DefaultLayout
  const flags = useFlags()
  useBrowserIncompatibilityChecker()

  useEffect(() => {
    DocumentEventObserver.initialize()
    AdobeIMS.initialize()
    AdobeAnalyticsWrapper.initialize()
    initializeLDClient()

    return () => {
      DocumentEventObserver.terminate()
    }
  }, [])

  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={RootStore}>
        <ThemeProvider>
          <RequireAuth>
            <RequireBetaAuthorization>
              <Layout>
                <Head>
                  <meta name="application-name" content="Project Neo" />
                  <meta name="apple-mobile-web-app-capable" content="yes" />
                  <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="default"
                  />
                  <meta
                    name="apple-mobile-web-app-title"
                    content="Project Neo"
                  />
                  <meta
                    name="description"
                    content="Project Neo from 3D & Immersive Labs"
                  />
                  <meta name="format-detection" content="telephone=no" />
                  <meta name="mobile-web-app-capable" content="yes" />
                  <meta
                    name="msapplication-config"
                    content="/icons/browserconfig.xml"
                  />
                  <meta name="msapplication-TileColor" content="#2B5797" />
                  <meta name="msapplication-tap-highlight" content="no" />
                  <meta name="theme-color" content="#000000" />

                  <link
                    rel="apple-touch-icon"
                    href="/icons/touch-icon-iphone.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/icons/touch-icon-ipad.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/icons/touch-icon-iphone-retina.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="167x167"
                    href="/icons/touch-icon-ipad-retina.png"
                  />

                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/icons/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/icons/favicon-16x16.png"
                  />
                  <link rel="manifest" href="/manifest.json" />
                  <link
                    rel="mask-icon"
                    href="/icons/safari-pinned-tab.svg"
                    color="#5bbad5"
                  />
                  <link rel="shortcut icon" href="/favicon.ico" />
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
                  />

                  <meta name="twitter:card" content="summary" />
                  <meta
                    name="twitter:url"
                    content="https://projectneo.adobe.com"
                  />
                  <meta name="twitter:title" content="Project Neo" />
                  <meta
                    name="twitter:description"
                    content="Project Neo from 3D & Immersive Labs"
                  />
                  <meta
                    name="twitter:image"
                    content="icons/android-chrome-192x192.png"
                  />
                  <meta name="twitter:creator" content="@DavidWShadow" />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content="Project Neo" />
                  <meta
                    property="og:description"
                    content="Project Neo from 3D"
                  />
                  <meta property="og:site_name" content="Project Neo" />
                  <meta
                    property="og:url"
                    content="https://projectneo.adobe.com"
                  />
                  <meta
                    property="og:image"
                    content="icons/apple-touch-icon.png"
                  />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <meta name="theme-color" content="#fff" />
                  <script
                    src={process.env.NEXT_PUBLIC_ADOBE_TAGMANAGER_URL}
                    async></script>
                </Head>
                <Component key={router.asPath} {...pageProps} />
              </Layout>
            </RequireBetaAuthorization>
          </RequireAuth>
          {flags['debug-tf-fx-fullstory'] && (
            <Script>
              {`
                  window['_fs_host'] = 'fullstory.com';
                  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                  window['_fs_org'] = 'o-1X0HP2-na1';
                  window['_fs_namespace'] = 'FS';
                  !function(m,n,e,t,l,o,g,y){var s,f,a=function(h){
                  return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),!1)}(e)
                  ;function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}
                  d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{
                  then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){
                  h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}
                  ;function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){
                  "function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){
                  (o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}
                  ;var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}
                  function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h("setProperties",{type:b,properties:d},j)}function j(b,h){d("user",b,h)}function r(b,h,d){j({
                  uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h("trackEvent",{
                  name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},
                  g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",
                  f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v="2.0.0")
                  }(window,document,window._fs_namespace,"script",window._fs_script);
              `}
            </Script>
          )}
          <Script
            src={`/new-relic-init-${process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT}.js`}
            async
          />
        </ThemeProvider>
      </ReduxProvider>
    </ApolloProvider>
  )
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false
  }
})(AppRoot)
