import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicRadio = dynamic(
	async () => await import('@swc-react/radio').then(({ Radio }) => Radio),
	{
		ssr: false,
		loading: () => <></>,
	}
)

export const Radio: typeof DynamicRadio = (props) => {
	useEffect(() => {
		import('@spectrum-web-components/radio/sp-radio.js')
	}, [])

	return <DynamicRadio {...props} />
}
